import React from 'react';
//import MenuOpciones from '../componentes/menus/menuopciones';
import '../estilos/homeAdmin.css'
import { fire } from '../funciones/firebase'
import { collection, query, where, getDocs, doc, onSnapshot, addDoc } from 'firebase/firestore'
import TablaPosiciones from '../componentes/administrador/tablaPosiciones';
//import banner from '../respaldos/banner-logo.png'
import ListaEquipos from '../componentes/administrador/listaEquipos';
import FixtureGeneral from '../componentes/administrador/fixturegeneral';
import TablaGoleadores from '../componentes/administrador/listaGoleadores';

export default class HomeUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idcampeonato: '',
      nombrecampeonato: 'PRUEBA',
      datosCampeonatos: [],
      Campeonato: {},

      catNombre: [],
      etapas: [],
      equiposCampeonato: [],
      catGrupos: [],
      equiposCategoria: [],
      equiposGrupos: [],
      equiposUnidos: [],
      ListaequiposCategoria: [],
      fixtureGeneral: [],
      fixtureok: [],
      fechas: [],

      etapaSeleccionada: '',
      categoriaElegida: 'ELIGE UNA CATEGORIA',
      grupoElegido: 'ELIGE',
      eleccionMenu: '',

      mostrarGoleadores: false,
      mostrarInfo: false,
      mostrarEquipos: false,
      mostrarPosiciones: false,
      mostrarFixture: false,
      mostrarAcumulada: false,
      mostrarClasificatoria: false,
      mostrarEtapa: true,
    }
  }
  componentDidMount() {
    this.getDatos()
  }
  getDatos = async () => {
    const q = query(collection(fire, "listacampeonatos"));
    const un = onSnapshot(q, (querySnapshot) => {
      const campeonatos = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        campeonatos.push({ id, ...data })
      });
      ////console.log(campeonatos);
      this.setState({
        datosCampeonatos: campeonatos,
      })
    },
      (error) => {
        //console.log(error)
      });
  }

  getFixture = async (id) => {
    ////console.log('BUUUU')
    const q = query(collection(fire, "listacampeonatos", `${id}`, "fixture"));
    onSnapshot(q, (querySnapshot) => {
      const fixture = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        fixture.push({ id, ...data })
      });
      ////console.log(fixture);
      this.setState({
        fixtureGeneral: fixture,
      })
    },
      (error) => {
        //console.log(error)
      });
  }
  
  traerCampeonato = (e) => {
    let Campeonato = this.state.datosCampeonatos
    let id = e.target.value
    ////console.log(id)
    if(id !== this.state.idcampeonato){
      this.setState({
        categoriaElegida: 'ELIGE UNA CATEGORIA',
          grupoElegido: 'ELIGE',
          eleccionMenu: '',
          mostrarGoleadores: false,
          mostrarInfo: false,
          mostrarEquipos: false,
          mostrarPosiciones: false,
          mostrarFixture: false,
          mostrarAcumulada: false,
          mostrarClasificatoria: false,
      })
    }

    Campeonato.forEach(doc => {
      if (id === doc.id) {
        let categorias = []
        ////console.log(doc.id)
        ////console.log(doc.etapas[0].nombre)
        this.setState({
          idcampeonato: id,
          Campeonato: doc,
          nombrecampeonato: doc.nombre,
          etapas: doc.etapas,
          etapaSeleccionada: doc.etapas[0].nombre,
          fechas: doc.fechas
        })
        doc.etapas[0].categorias.forEach((doc, i) => {
          categorias.push(doc.nombre)
          this.setState({
            catNombre: categorias,
            mostrarCampeonato: true,
          })
        })
        this.getEquipos(id)
        this.getFixture(id)
      }
    })
    
  }

  getEquipos = async (id) => {
    const q =  query(collection(fire, "equipos"), where('idcampeonato', '==', `${id}`));
    
    await onSnapshot(q, (querySnapshot) => {
      const equipos = [];
      ////console.log(querySnapshot);
      querySnapshot.forEach((doc ) => {
        const id = doc.id;
        const data = doc.data();
        equipos.push({ id, ...data })
      });
      ////console.log('equipos');
      this.setState({
        equiposCampeonato: equipos
      })
    },
      (error) => {
        //console.log(error)
      });
  }

  eligeCategoria = (doc) => {
    
    let categoria = doc.target.value
    ////console.log('CAT '+categoria)
    let id = parseInt(doc.target.id)
    let Campeonato = this.state.Campeonato
    ////console.log(categoria)
    Campeonato.etapas[0].categorias.forEach((doc, i) => {
      if (categoria === doc.nombre) {
        ////console.log(doc.grupos)
        this.setState({
          catGrupos: doc.grupos
        })
      }
    })
    this.setState({
      categoriaElegida: categoria
    })
    if (this.state.mostrarPosiciones === true) {
      let cat = categoria
      let gru = this.state.grupoElegido
      let eta = this.state.etapaSeleccionada
      this.SepararCategorias(cat, gru, eta)
    }
    if (this.state.mostrarEquipos === true) {
      let cat = categoria
      let gru = this.state.grupoElegido
      let eta = this.state.etapaSeleccionada
      this.SepararCategorias(cat, gru, eta)
      this.SepararEquipos(cat)
    }

    if(this.state.mostrarFixture === true){
      if(categoria === 'General'){
        let eta = this.state.etapaSeleccionada
        this.mostrarTodoFixture(eta)
      } else{
        let cat = categoria
        let eta = this.state.etapaSeleccionada
        this.SepararCategoriasFixture(cat, eta)
      }
    }

  }

  mostrarTodoFixture=(eta)=>{
    let fixtureGeneral = this.state.fixtureGeneral
    let fechas = this.state.fechas
    if(fixtureGeneral){
      let fixtureok = []
      let fechasaux = []
      let fechasok = []
      fixtureGeneral.forEach(doc=>{
        if(doc.etapa === eta){
          fixtureok.push(doc)
        }
      })
      fechas.forEach(doc=>{
        if(doc.etapa === eta){
          fechasaux.push(doc)
        }
      })

      let hash = {};
      fechasaux = fechasaux.filter(function(current) {
        let exists = !hash[current.fecha];
        hash[current.fecha] = true;
        return exists;
      });

      fechasok = fechasaux.sort((a,b)=>{
        return a.fecha - b.fecha
      })
      
      this.setState({
        fixtureok: fixtureok,
        fechasok: fechasok
      })
    }
    ////console.log(eta)
  }

  SepararCategoriasFixture=(cat, eta)=>{
    let fixtureGeneral = this.state.fixtureGeneral
    let fechas = this.state.fechas
    if(fixtureGeneral){
      let fixtureok = []
      let fechasok = []
      fixtureGeneral.forEach(doc=>{
        if(doc.etapa === eta){
          if(doc.idcategoria === cat){
            fixtureok.push(doc)
          }
        }
      })
      fechas.forEach(doc=>{
        if(doc.etapa === eta){
          if(doc.categoria === cat){
            fechasok.push(doc)
          }
        }
      })

      fechasok.sort((a,b)=>{
        return a.fecha - b.fecha
      })
      this.setState({
        fixtureok: fixtureok,
        fechasok: fechasok
      })
    }
  }

  SepararEquipos=(cat)=>{
    let equiposCampeonato = this.state.equiposCampeonato
    let equipos = []
    ////console.log(cat)
    equiposCampeonato.forEach((doc,i)=>{
      ////console.log(doc)
      if(doc.idcategoria === cat){
        equipos.push(doc)
      }
      
    })
    this.setState({
      ListaequiposCategoria: equipos
    })
    ////console.log(equipos.length)
  }

  eligeGrupo = (doc) => {
    let grupo = doc.target.value
    this.setState({
      grupoElegido: grupo
    })
    let cat = this.state.categoriaElegida
    let gru = grupo
    let eta = this.state.etapaSeleccionada
    this.SepararCategorias(cat, gru, eta)
  }

  posiciones = () => {
    this.setState({
      eleccionMenu: 'TABLA DE POSICIONES',
      mostrarGoleadores: false,
      mostrarInfo: false,
      mostrarEquipos: false,
      mostrarPosiciones: true,
      mostrarFixture: false
    })
    let cat = this.state.categoriaElegida
    let gru = this.state.grupoElegido
    let eta = this.state.etapaSeleccionada
    this.SepararCategorias(cat, gru, eta)
  }

  SepararCategorias = (cat, gru, eta) => {
    let equipos = this.state.equiposCampeonato
    let categoria = cat
    let grupo = gru
    let etapa = 'etapa 1'
    if(eta !== 'ACUMULADA' && eta !== 'CLASIFICATORIA'){
      etapa = eta
    }
    let equiposCategoria = []
    let equiposGrupos = []
    let arreglados = []
    let grupos = this.state.catGrupos
    let equiposGruposAux = []
    
    ////console.log(grupo)
    if (equipos && categoria && grupos) {
      equipos.forEach((aux, i) => {
        aux.etapas.forEach((doc, i) => {
          ////console.log(doc.idgrupos)
          if (doc.etapanombre === etapa) {

            if (doc.idcategoria === categoria) {
              equiposCategoria.push({
                puntos: aux.acumulada.puntos,
                diferencia: aux.acumulada.goldiferencia,
                jugados: aux.acumulada.jugados,
                ganados: aux.acumulada.ganados,
                empatados: aux.acumulada.empatados,
                perdidos: aux.acumulada.perdidos,
                favor: aux.acumulada.afavor,
                contra: aux.acumulada.encontra,
                nombre: aux.nombre,
                grupo: doc.idgrupos
              })
              if (doc.idgrupos === grupo) {
                ////console.log('etapa')
                if(etapa === 'etapa 1'){
                  equiposGrupos.push({
                    puntos: aux.tabla1.puntos,
                    diferencia: aux.tabla1.goldiferencia,
                    jugados: aux.tabla1.jugados,
                    ganados: aux.tabla1.ganados,
                    empatados: aux.tabla1.empatados,
                    perdidos: aux.tabla1.perdidos,
                    favor: aux.tabla1.afavor,
                    contra: aux.tabla1.encontra,
                    nombre: aux.nombre,
                    grupo: doc.idgrupos
                  })
                }
                if(etapa === 'etapa 2'){
                  equiposGrupos.push({
                    puntos: aux.tabla2.puntos,
                    diferencia: aux.tabla2.goldiferencia,
                    jugados: aux.tabla2.jugados,
                    ganados: aux.tabla2.ganados,
                    empatados: aux.tabla2.empatados,
                    perdidos: aux.tabla2.perdidos,
                    favor: aux.tabla2.afavor,
                    contra: aux.tabla2.encontra,
                    nombre: aux.nombre,
                    grupo: doc.idgrupos
                  })
                }
              }
            }
          }
          ////console.log(equiposCategoria.length)
          equiposCategoria.sort(function (a, b) {
            if (a.puntos > b.puntos) {
              return -1;
            }
            if (a.puntos < b.puntos) {
              return 1;
            }
            // a must be equal to b
            else {
              if (a.diferencia > b.diferencia) {
                return -1;
              }
              if (a.diferencia < b.diferencia) {
                return 1;
              } else {
                if (a.favor > b.favor) {
                  return -1;
                }
                if (a.favor < b.favor) {
                  return 1;
                } else {
                  if (a.contra > b.contra) {
                    return 1;
                  }
                  if (a.contra < b.contra) {
                    return -1;
                  }
                }
              }
            }
            return 0;
          });
          equiposGrupos.sort(function (a, b) {
            if (a.puntos > b.puntos) {
              return -1;
            }
            if (a.puntos < b.puntos) {
              return 1;
            }
            // a must be equal to b
            else {
              if (a.diferencia > b.diferencia) {
                return -1;
              }
              if (a.diferencia < b.diferencia) {
                return 1;
              } else {
                if (a.favor > b.favor) {
                  return -1;
                }
                if (a.favor < b.favor) {
                  return 1;
                } else {
                  if (a.contra > b.contra) {
                    return 1;
                  }
                  if (a.contra < b.contra) {
                    return -1;
                  }
                }
              }
            }
            return 0;
          });

        })
      })
      
      grupos.forEach(aux => {
        let eqgru = []
        equiposCategoria.forEach(doc => {
          if (aux === doc.grupo) {
            ////console.log(doc)
            eqgru.push(doc)
          }
        })
        equiposGruposAux.push(eqgru)
      })
      ////console.log(equiposGruposAux)
      let aux1 = []
      equiposGruposAux.forEach(doc => {
        aux1.push(doc.length)

      })
      ////console.log(equiposGruposAux)
      let maximo = Math.max(...aux1)
      
      ////console.log(maximo)
      for (let a = 0; a < maximo; a++) {
        let auxi = []
        equiposGruposAux.forEach((doc, i) => {
          doc.forEach((aux, index) => {
            ////console.log(index + ' ' + a)
            if (index === a) {
              auxi.push(aux)
            }
            ////console.log('auxi')
            ////console.log(auxi)
            auxi.sort(function (a, b) {
              if (a.puntos > b.puntos) {
                return -1;
              }
              if (a.puntos < b.puntos) {
                return 1;
              }
              // a must be equal to b
              else {
                if (a.diferencia > b.diferencia) {
                  return -1;
                }
                if (a.diferencia < b.diferencia) {
                  return 1;
                } else {
                  if (a.favor > b.favor) {
                    return -1;
                  }
                  if (a.favor < b.favor) {
                    return 1;
                  } else {
                    if (a.contra > b.contra) {
                      return 1;
                    }
                    if (a.contra < b.contra) {
                      return -1;
                    }
                  }
                }
              }
              return 0;
            });
          })
        })
        arreglados.push(auxi)
      }
      
      ////console.log(arreglados.length)
      let unidos = []
      arreglados.forEach(doc => {
        doc.forEach(aux => {
          unidos.push(aux)
        })

      })

      ////console.log('unidos')
      ////console.log(unidos)
      this.setState({
        equiposCategoria: equiposCategoria,
        equiposGrupos: equiposGrupos,
        equiposUnidos: unidos
      })
    }
  }

  equipos = () => {
    this.setState({
      eleccionMenu: 'LISTA DE EQUIPOS',
      mostrarGoleadores: false,
      mostrarInfo: false,
      mostrarEquipos: true,
      mostrarPosiciones: false,
      mostrarFixture: false
    })
    let cat = this.state.categoriaElegida
    let gru = this.state.grupoElegido
    let eta = this.state.etapaSeleccionada
    this.SepararCategorias(cat, gru, eta)
  }
  goleadores = () => {
    this.setState({
      eleccionMenu: 'LISTA DE GOLEADORES',
      mostrarGoleadores: true,
      mostrarInfo: false,
      mostrarEquipos: false,
      mostrarPosiciones: false,
      mostrarFixture: false
    })
  }
  fixture = () => {
    this.setState({
      eleccionMenu: 'FIXTURE',
      mostrarGoleadores: false,
      mostrarInfo: false,
      mostrarEquipos: false,
      mostrarPosiciones: false,
      mostrarFixture: true
    })
  }
  amonestados = () => {
    this.setState({
      eleccionMenu: 'AMONESTADOS',
      mostrarGoleadores: false,
      mostrarInfo: false,
      mostrarEquipos: false,
      mostrarPosiciones: false,
      mostrarFixture: false
    })
  }
  informacion = () => {
    this.setState({
      eleccionMenu: 'INFORMACIÓN',
      mostrarGoleadores: false,
      mostrarInfo: true,
      mostrarEquipos: false,
      mostrarPosiciones: false,
      mostrarFixture: false
    })
  }

  mostrarAcumulada=()=>{
    this.setState({
      mostrarAcumulada: true,
      mostrarClasificatoria: false,
      mostrarEtapa: false,
      etapaSeleccionada: 'ACUMULADA'
    })
    let cat = this.state.categoriaElegida
    let gru = this.state.grupoElegido
    let eta = this.state.etapaSeleccionada
    this.SepararCategorias(cat, gru, eta)
  }
  mostrarClasificatoria=()=>{
    this.setState({
      mostrarAcumulada: false,
      mostrarClasificatoria: true,
      mostrarEtapa: false,
      etapaSeleccionada: 'CLASIFICATORIA'
    })
    let cat = this.state.categoriaElegida
    let gru = this.state.grupoElegido
    let eta = this.state.etapaSeleccionada
    this.SepararCategorias(cat, gru, eta)
  }

  mostrarEtapa=(doc)=>{
    let etapa = doc.target.value
    this.setState({
      mostrarAcumulada: false,
      mostrarClasificatoria: false,
      mostrarEtapa: true,
      etapaSeleccionada: etapa
    })
    let cat = this.state.categoriaElegida
    let gru = this.state.grupoElegido
    let eta = etapa
    this.SepararCategorias(cat, gru, eta)
  }

  fondo = (doc) => {
    ////console.log(doc)
    if (doc === this.state.etapaSeleccionada) {
      return { backgroundColor: '#E4ECE7', color: 'black' }
    }
    if (doc === this.state.categoriaElegida) {
      return { backgroundColor: '#E4ECE7', color: 'black' }
    }
    if (doc === this.state.eleccionMenu) {
      return { backgroundColor: '#E4ECE7', color: 'black' }
    }
    if (doc === this.state.grupoElegido) {
      return { backgroundColor: '#E4ECE7', color: 'black' }
    }
  }
  CerrarSesion=()=>{
    this.props.CerrarSesion()
  }
  render() {
    let FixGeneral = 'General'
    return (
      <div className='Todo'>
        <div className='Header'>
          <div className='LogoHeader'>
            <img className='bannerLogo' alt='bannerLogo' src={require('../respaldos/banner-logo.png')} />
          </div>
          <div className='MenuHeader'>
            <select id='Campeonatos' name='Campeonatos' onChange={(e) => this.traerCampeonato(e)} >
              <option value='' >Elige un Campeonato</option>
              {this.state.datosCampeonatos.map((doc, i) => {
                return <option key={doc.id} value={doc.id}> {doc.nombre} </option>
              })}
            </select>
            <button onClick={this.CerrarSesion} className='CerrarSesion'>CERRAR SESION</button>
            <button className='CerrarSesion'>CERRAR SESION</button>
          </div>
        </div>
        <div className='Body'>
          <div  className='TodoMenu'>
            <button style={this.fondo('TABLA DE POSICIONES')} onClick={this.posiciones} className='Menu1'>TABLA DE POSICIONES</button>
            <button style={this.fondo('LISTA DE EQUIPOS')} onClick={this.equipos} className='Menu2'>LISTA DE EQUIPOS</button>
            <button style={this.fondo('FIXTURE')} onClick={this.fixture} className='Menu2'>FIXTURE</button>
            {/*
            <button style={this.fondo('AMONESTADOS')} onClick={this.amonestados} className='Menu1'>AMONESTADOS</button>
            <button style={this.fondo('LISTA DE GOLEADORES')} onClick={this.goleadores} className='Menu1'>LISTA DE GOLEADORES</button>
            <button style={this.fondo('INFORMACIÓN')} onClick={this.informacion} className='Menu2'>INFORMACIÓN</button>
            */}
            </div>
          <div className='TodoMenu'>
            {this.state.mostrarFixture === true ? (
              <button key='FixGeneral' value='General' onClick={(FixGeneral) => this.eligeCategoria(FixGeneral)} style={this.fondo(FixGeneral)} className='BotonesCategoria'>FIXTURE GENERAL</button>
            ):null}
            {this.state.catNombre && this.state.catNombre.map((doc, i) => {
              return (
                <button key={i} value={doc} onClick={(doc) => this.eligeCategoria(doc)} style={this.fondo(doc)} className='BotonesCategoria'>{doc}</button>
              )
            })}
          </div>
          <div className='BodyGeneral'>
            <div className='botonesBody'>
              
              {this.state.etapas && this.state.mostrarPosiciones === true ? this.state.etapas.map((doc, i) => {
                return (
                  <button key={i} onClick={(doc)=>this.mostrarEtapa(doc)} value={doc.nombre} style={this.fondo(doc.nombre)} className='BotonesEtapa'>{doc.nombre}</button>
                )
              }) : null}
              {this.state.etapas && this.state.mostrarFixture === true ? this.state.etapas.map((doc, i) => {
                return (
                  <button key={i} onClick={(doc)=>this.mostrarEtapa(doc)} value={doc.nombre} style={this.fondo(doc.nombre)} className='BotonesEtapa'>{doc.nombre}</button>
                )
              }) : null}
              {this.state.mostrarPosiciones === true ? (
                <div>
                  <button style={this.fondo('ACUMULADA')} onClick={this.mostrarAcumulada} className='BotonesEtapa'>ACUMULADA</button>
                  <button style={this.fondo('CLASIFICATORIA')} onClick={this.mostrarClasificatoria} className='BotonesEtapa'>CLASIFICATORIA</button>
                </div>
              ) : null}
            </div>
            <div className='botonesBody'>
            {this.state.mostrarPosiciones === true ? this.state.catGrupos.map((doc, i) => {
                return (
                  <button key={i} onClick={(doc) => this.eligeGrupo(doc)} value={doc} style={this.fondo(doc)} className='BotonesGrupos'>GRUPO: {doc}</button>
                )
              }
              ) : null}
              </div>
            <br />
            {this.state.mostrarCampeonato === true && (
              <div>
                <p className='nombreCampeonato'>{this.state.nombrecampeonato}</p>
                <br />
                
              </div>
            )}
            <div>
              {this.state.mostrarPosiciones === true ? (
                <TablaPosiciones className='tablaPosiciones'
                  eleccionMenu={this.state.eleccionMenu}
                  categoriaElegida={this.state.categoriaElegida}
                  grupoElegido={this.state.grupoElegido}
                  idCampeonato={this.state.idcampeonato}
                  etapaSeleccionada={this.state.etapaSeleccionada}
                  usuario={'usuario'}

                  catGrupos={this.state.catGrupos}
                  mostrarAcumulada={this.state.mostrarAcumulada}
                  mostrarClasificatoria={this.state.mostrarClasificatoria}
                  mostrarEtapa={this.state.mostrarEtapa}
                  equiposCategoria={this.state.equiposCategoria}
                  equiposGrupos={this.state.equiposGrupos}
                  equiposUnidos={this.state.equiposUnidos}
                  idgrupos={this.state.grupoElegido}
                  idcategoria={this.state.categoriaElegida}

                  Campeonato={this.state.Campeonato}
                />
              ) : null}
            </div>
            <div>
              {this.state.mostrarEquipos === true ? (
                <ListaEquipos className='tablaPosiciones'
                  eleccionMenu={this.state.eleccionMenu}
                  categoriaElegida={this.state.categoriaElegida}
                  grupoElegido={this.state.grupoElegido}
                  idCampeonato={this.state.idcampeonato}
                  etapaSeleccionada={this.state.etapaSeleccionada}
                  usuario={'usuario'}

                  catGrupos={this.state.catGrupos}
                  mostrarAcumulada={this.state.mostrarAcumulada}
                  mostrarClasificatoria={this.state.mostrarClasificatoria}
                  mostrarEtapa={this.state.mostrarEtapa}
                  equiposCategoria={this.state.equiposCategoria}
                  equiposGrupos={this.state.equiposGrupos}
                  equiposUnidos={this.state.equiposUnidos}
                  idgrupos={this.state.grupoElegido}
                  idcategoria={this.state.categoriaElegida}

                  ListaequiposCategoria={this.state.ListaequiposCategoria}
                />
              ) : null}
            </div>
            <div>
              {this.state.mostrarFixture === true ? (
                <FixtureGeneral className='fixtureGeneral'
                  eleccionMenu={this.state.eleccionMenu}
                  categoriaElegida={this.state.categoriaElegida}
                  grupoElegido={this.state.grupoElegido}
                  idCampeonato={this.state.idcampeonato}
                  etapaSeleccionada={this.state.etapaSeleccionada}
                  usuario={'usuario'}

                  catGrupos={this.state.catGrupos}
                  mostrarAcumulada={this.state.mostrarAcumulada}
                  mostrarClasificatoria={this.state.mostrarClasificatoria}
                  mostrarEtapa={this.state.mostrarEtapa}
                  equiposCategoria={this.state.equiposCategoria}
                  equiposGrupos={this.state.equiposGrupos}
                  equiposUnidos={this.state.equiposUnidos}
                  idgrupos={this.state.grupoElegido}
                  idcategoria={this.state.categoriaElegida}

                  fixtureok={this.state.fixtureok}
                  fechas={this.state.fechasok}
                  Campeonato={this.state.Campeonato}

                  ListaequiposCategoria={this.state.ListaequiposCategoria}
                  SepararCategoriasFixture={this.SepararCategoriasFixture}
                />
              ) : null}
              {this.state.mostrarGoleadores === true ? (
                <TablaGoleadores className='fixtureGeneral'
                  eleccionMenu={this.state.eleccionMenu}
                  categoriaElegida={this.state.categoriaElegida}
                  grupoElegido={this.state.grupoElegido}
                  idCampeonato={this.state.idcampeonato}
                  etapaSeleccionada={this.state.etapaSeleccionada}

                  catGrupos={this.state.catGrupos}
                  mostrarAcumulada={this.state.mostrarAcumulada}
                  mostrarClasificatoria={this.state.mostrarClasificatoria}
                  mostrarEtapa={this.state.mostrarEtapa}
                  equiposCategoria={this.state.equiposCategoria}
                  equiposGrupos={this.state.equiposGrupos}
                  equiposUnidos={this.state.equiposUnidos}
                  idgrupos={this.state.grupoElegido}
                  idcategoria={this.state.categoriaElegida}

                  fixtureok={this.state.fixtureok}
                  fechas={this.state.fechasok}
                  Campeonato={this.state.Campeonato}

                  ListaequiposCategoria={this.state.ListaequiposCategoria}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className='Foot'>
          FOOT ADMINISTRADOR
        </div>
      </div>
    )
  };
}
