import React from 'react';
import '../../estilos/fixture.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Document, Page, View, Text, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../respaldos/logo-TACTICAPP.png'
import { fire } from '../../funciones/firebase'
import { updateDoc, doc } from 'firebase/firestore';
import EscudoGenerico from '../../respaldos/icono-escudoequipo.png'

export default class FixtureFecha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarFecha: false,
            fechaElegida: '',

            fondoGrupal: '',
            fondoAcumulada: '',
            fondoClasificatoria: '',
            bannerGrupal: '',
            bannerAcumulada: '',
            bannerClasificatoria: '',
            colorTitulo: { color: 'black' },
            colorTituloNombre: { color: '#063652' },
            pieImagen: '../../respaldos/pie-de-imagen-TP.png',
            prueba: '../../respaldos/logo-TACTICAPP.png',
            colorLetraTabla: { color: 'black' },
            PNGgrupo: '',
            PNGAcumulada: '',
            PNGclasificatoria: '',
            JPGGrupo: '',
            JPGAcumulada: '',
            JPGclasificatoria: '',
            fondoTP: null,
            bannerbajoTP: null,

            fixtureFecha: [],
            mostrarHojaVocalia: false,
            mostrarHojaVocaliaindex: null,
            mostrarHojaVocaliaGeneral: false,
            hojaVocalia: {},
            informePenas: '',
            informePenasok: '',
            aux: false,
            escudos: ['1', '2', '3'],
            escudoEquipoLocal: '',
            escudoEquipoVisitante: ''
        }
        //this.MostrarFotoLocal = this.MostrarFotoLocal.bind(this)

    }

    componentDidMount() {
        //this.TraerEscudoLocal()
        //this.TraerEscudoVisitante()
        this.ponerdatos()
    }

    ponerdatos=()=>{
        let informePenas = this.props.data.informePenas
        this.setState({
            informePenas: informePenas
        })
    }

    fondo = (i) => {
        let aux = this.props.catGrupos.length
        if (i < aux) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 2) - 1 && i < (aux * 3)) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 4) - 1 && i < (aux * 5)) {
            return { backgroundColor: '#E4ECE7' }
        }

    }

    fondo1 = (i) => {
        let aux = this.props.catGrupos.length
        if (i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    traerFondo = async () => {

        this.setState({
            fondoGrupal: fondo,
            fondoAcumulada: fondo,
            fondoClasificatoria: fondo
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)

        let fondoFire = await getDownloadURL(referencia).then(doc => {
            if (doc) {

                this.setState({
                    fondoGrupal: doc,
                    fondoAcumulada: doc,
                    fondoClasificatoria: doc
                })
            }

        }
        ).catch(e => {
            //console.log(e)
        })
    }

    traerBannerBajo = async () => {

        this.setState({
            bannerGrupal: banner,
            bannerAcumulada: banner,
            bannerClasificatoria: banner
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)

        let fondoFire = await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {

                this.setState({
                    bannerGrupal: doc,
                    bannerAcumulada: doc,
                    bannerClasificatoria: doc,
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    descargarPngFixtureGeneral = () => {
        let node = document.getElementById('fixtureGeneral')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                //var img = new Image();
                //img.src = dataUrl;
                this.setState({
                    PNGgrupo: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGFixtureGeneral = () => {

        let node = document.getElementById('fixtureGeneral')

        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                //var img = new Image();
                //img.src = dataUrl;
                this.setState({
                    JPGGrupo: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });

    }

    descargarPngVocalia = () => {

        let node = document.getElementById('tablaAcumulada')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGAcumulada: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGVocalia = () => {
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGAcumulada: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngFixtureCategoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                img.crossOrigin = 'Anonymous'
                this.setState({
                    PNGclasificatoria: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGFixtureCategoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGclasificatoria: dataUrl
                })

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    SubirFondo = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('FondoTP').files[0]

        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    fondoTP: null
                })
            )
            this.traerFondo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    SubirBanerTP = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('BannerBajoTP').files[0]

        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    bannerbajoTP: null
                })
            )
            this.traerBannerBajo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    elegirFoto = async (e) => {

        let idAux = e.target.id
        let foto = e.target.files[0]

        let fotoOk = new FileReader()
        let url = fotoOk.readAsDataURL(foto)

        fotoOk.onloadend = function (e) {
            if (idAux === 'FondoTP') {
                this.setState({
                    fondoTP: [fotoOk.result]
                })
            }
            if (idAux === 'BannerBajoTP') {
                this.setState({
                    bannerbajoTP: [fotoOk.result]
                })
            }
        }.bind(this);

    }

    mostrarFecha = (doc) => {

        let FixtureGeneral = this.props.fixtureok
        let fixture = []
        this.setState({
            mostrarFecha: true,
            fechaElegida: doc
        })
        if (doc === this.state.fechaElegida) {
            if (this.state.aux === false) {
                this.setState({
                    mostrarFecha: false,
                    fechaElegida: ''
                })
            }
        }
        FixtureGeneral.map(aux => {
            if (aux.numerofecha === doc.fecha) {
                fixture.push(aux)
            }
        })
        fixture.sort((a, b) => {
            if (a.fecha.dia > b.fecha.dia) {
                return -1
            }
            if (a.fecha.dia < b.fecha.dia) {
                return 1
            } else {
                if (a.fecha.ordenar < b.fecha.ordenar) {
                    return -1
                }
                if (a.fecha.ordenar > b.fecha.ordenar) {
                    return 1
                }
                return 0
            }
            return 0
        })

        this.setState({
            fixtureFecha: fixture
        })
    }

    RevisarVocalia = (doc, i) => {

        if (i === null) {
            this.setState({
                mostrarHojaVocalia: false,
                mostrarHojaVocaliaindex: i,
                hojaVocalia: doc
            })
        } else {
            this.setState({
                mostrarHojaVocalia: true,
                mostrarHojaVocaliaindex: i,
                hojaVocalia: doc
            })
        }

    }

    mostrarHojaVocaliaGeneral = () => {
        this.setState({
            mostrarHojaVocaliaGeneral: !this.state.mostrarHojaVocaliaGeneral
        })
    }
    /*
    TraerEscudoLocal = async () => {

        let Auxnombre = this.props.data.local.equiponombre
        let nombre = Auxnombre.replace(/ /g, "").toLowerCase()
        let idCampeonato = this.props.idCampeonato
        let nombreFoto = 'escudo' + idCampeonato + nombre
        let referencia = await ref(storage, `escudos/${nombreFoto}`)

        await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                this.setState({
                    escudoEquipoLocal: doc
                })
            }

        }
        ).catch(e => {
            ////console.log(e)
        })
    }
    TraerEscudoVisitante = async () => {

        let Auxnombre = this.props.data.visitante.equiponombre
        let nombre = Auxnombre.replace(/ /g, "").toLowerCase()
        let idCampeonato = this.props.idCampeonato
        let nombreFoto = 'escudo' + idCampeonato + nombre


        let referencia = await ref(storage, `escudos/${nombreFoto}`)

        await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                this.setState({
                    escudoEquipoVisitante: doc
                })
            }
        }
        ).catch(e => {
            ////console.log(e)
        })
    }

    MostrarFotoLocal = () => {
        const { escudoEquipoLocal } = this.state

        let referenciaok = this.props.data.local.escudo

        if (referenciaok !== undefined && referenciaok.length !== 0) {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={referenciaok} />
            )
        }
        else {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
            )
        }
    }

    MostrarFotoVisitante = () => {
        let referenciaok = this.props.data.visitante.escudo
        const { escudoEquipoVisitante } = this.state

        if (referenciaok !== undefined && referenciaok.length !== 0) {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={referenciaok} />
            )
        }
        else {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
            )
        }
    }
    */

    RegistrarInforme = async (aux) => {

        let id = this.props.idCampeonato
        let cat = this.props.categoriaElegida
        let eta = this.props.etapaSeleccionada
        let gru = this.props.grupoElegido
        let au = true
        let fech = this.props.fechaElegida.fecha

        let partido = aux.id
        let informePenas = this.state.informePenas
        const Informacion = doc(fire, "listacampeonatos", `${id}`, "fixture", `${partido}`)
        await updateDoc(Informacion, {
            informePenas: informePenas,
        }).then(() => {
            alert('REGISTRADO')
            this.setState({
                aux: true
            })
            this.props.eligeCategoria2(cat, fech,eta) 
        })
    }

    render() {
        return (
            <div className='tablaPosiciones'>

                <div className='fixture'>
                    <div className='dia'>
                        <p className='textoFixGen'>{this.props.data.fecha.dia}</p>
                    </div>
                    <div style={{ fontWeight: 'bold' }} className='hora'>
                        <p className='textoFixGen'>{this.props.data.fecha.hora}</p>
                    </div>
                    <div className='partido'>
                        <div className='partido1'>
                            <div className='partido3'>
                                <p className='textoFix1'>{this.props.data.local.equiponombre}</p>
                                <p className='textoFix3'>{this.props.data.local.goles.total}</p>
                                {//this.MostrarFotoLocal()
                                }
                                {this.props.data.local.escudo ? 
                                <img className='fotoEscudo1' alt='fotoEscudo' src={this.props.data.local.escudo} />
                                :
                                <img className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
                                }

                            </div>

                            <div className='partido4'>
                                <p style={{ marginTop: '20px' }} className='textoFix1'>vs.</p>
                            </div>
                            <div className='partido3'>
                                {//this.MostrarFotoVisitante()
                                }
                                {this.props.data.visitante.escudo ? 
                                <img className='fotoEscudo2' alt='fotoEscudo' src={this.props.data.visitante.escudo} />
                                :
                                <img className='fotoEscudo2' alt='fotoEscudo' src={EscudoGenerico} />
                                }
                                <p className='textoFix3'>{this.props.data.visitante.goles.total}</p>
                                <p className='textoFix1'>{this.props.data.visitante.equiponombre}</p>
                            </div>

                        </div>


                        {this.props.mostrarHojaVocaliaGeneral === true ? (
                            <div className='partido1'>
                                {this.state.mostrarHojaVocalia === true && this.state.mostrarHojaVocaliaindex === this.props.i ? (
                                    <button onClick={() => this.RevisarVocalia(this.props.data, null)}>CERRAR HOJA DE VOCALIA</button>
                                ) : (

                                    <button onClick={() => this.RevisarVocalia(this.props.data, this.props.i)}>REVISAR HOJA DE VOCALIA</button>
                                )}


                            </div>
                        ) : null}

                    </div>
                    <div className='estadio'>
                        <p style={{ fontWeight: 'bold' }} className='textoFixGen'>{this.props.data.idcategoria}</p>
                    </div>
                    <div className='cancha'>
                        <p className='textoFixGen'>{this.props.data.cancha}</p>
                    </div>
                    <div className='estadio'>
                        <p className='textoFixGen'>{this.props.data.estadio}</p>
                    </div>
                </div>
                <div className='HojaVocalia'>
                    {(this.state.mostrarHojaVocalia === true && this.state.hojaVocalia.id === this.props.data.id) ? (
                        <PDFViewer filename={`${this.props.data.fecha.dia} ${this.props.data.fecha.hora} ${this.props.data.fecha.fecha}`} style={{ flex: 8, width: '100%', height: '90vh' }}>
                            <Document >

                                <Page size='A4'>
                                    <View style={{ flex: 1 }}>
                                        <View style={{}} >
                                            <Image style={{ margin: '5px', width: '80px', height: '30px', alignSelf: 'center' }} src={logo} />
                                            <Text style={{ fontSize: "18pt", textAlign: 'center', fontWeight: 'bold', padding: '10px', color: "#063652" }}>Hoja de Vocalía {this.props.Campeonato.nombre}</Text>
                                            <View style={{ flexDirection: "row", padding: '5px', backgroundColor: '#063652', justifyContent: 'center' }}>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Fecha: {this.props.data.numerofecha} /</Text>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Dia: {this.props.data.fecha.dia} /</Text>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Hora: {this.props.data.fecha.hora} /</Text>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Fecha: {this.props.data.fecha.fecha} </Text>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Categoria: {this.props.data.idcategoria} /</Text>
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>Grupo: {this.props.data.idgrupo}</Text>
                                            </View>
                                            <View style={{ flexDirection: "row", padding: '5px', justifyContent: 'center' }}>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold' }}>Vocal: {this.props.data.vocal} /</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold' }}>Veedor: {this.props.data.veedor}/</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold' }}>Estadio: {this.props.data.estadio} /</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold' }}>Cancha: {this.props.data.cancha}</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                                <Text style={{ fontSize: '15pt', textAlign: 'center', fontWeight: 'bold', padding: '10px', color: '#063652' }}>{this.props.data.local.equiponombre}: {this.props.data.local.goles.total}</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>Valor de Vocalia: {this.props.data.local.pagovocalia.total} - Estado: {this.props.data.local.pagovocalia.estado}</Text>
                                                {this.props.data.local.pagovocalia.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'gray', color: 'white' }}>DETALLE</Text>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.pagovocalia.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <Text style={{ flex: 2, fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.detalle}:</Text>
                                                                        <Text style={{ flex: 1, fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.valor}</Text>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.nopresentancion === true ? (
                                                    <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO SE PRESENTA</Text>
                                                ) : null}
                                                {this.props.data.local.titulares ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>TITULARES</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.titulares.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.goles.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>GOLES</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.goles.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.suplentes ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>ENTRARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.suplentes.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.sale ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>SALIERON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.sale.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.amarillas.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'orange', color: 'white' }}>TARJETAS AMARILLAS</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.amarillas.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.local.rojas.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'red', color: 'white' }}>TARJETAS ROJAS</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.rojas.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.penales === true ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>TOTAL: {this.props.data.local.penales.total}</Text>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>PENALES ANOTARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.penales.anotaron.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>PENALES FALLARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.local.penales.fallaron.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>FIRMA DE CAPITAN</Text>
                                                {this.props.data.local.acepta === 'Acepta' ? (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>ACEPTA LA VOCALIA</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE CAPITAN: {this.props.data.local.nombrecapitan}</Text>
                                                    </View>
                                                ) : (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                    <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO ACEPTAN VOCALIA</Text>
                                                    <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE CAPITAN: {this.props.data.local.nombrecapitan}</Text>
                                                    </View>
                                                )}

                                                {this.props.data.local.infocapitan !== undefined ? (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>INFORME DEL CAPITAN:</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.local.infocapitan}</Text>
                                                    </View>
                                                ) : null}

                                            </View>
                                            <View style={{ flex: 1, flexDirection: 'column' }}>
                                                <Text style={{ fontSize: '15pt', textAlign: 'center', fontWeight: 'bold', padding: '10px', color: '#063652' }}>{this.props.data.visitante.equiponombre}: {this.props.data.visitante.goles.total}</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>Valor de Vocalia: {this.props.data.visitante.pagovocalia.total} - Estado: {this.props.data.visitante.pagovocalia.estado}</Text>
                                                {this.props.data.visitante.pagovocalia.detalle ? (
                                                    <View>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'gray', color: 'white' }}>DETALLE</Text>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.pagovocalia.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <Text style={{ flex: 2, fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.detalle}:</Text>
                                                                        <Text style={{ flex: 1, fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.valor}</Text>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.nopresentancion === true ? (
                                                    <Text style={{ margin: '5px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO SE PRESENTA</Text>
                                                ) : null}
                                                {this.props.data.visitante.titulares ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>TITULARES</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'black', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.titulares.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.goles.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>GOLES</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.goles.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.suplentes ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>ENTRARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.suplentes.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.sale ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>SALIERON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.sale.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.amarillas.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'orange', color: 'white' }}>TARJETAS AMARILLAS</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.amarillas.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.visitante.rojas.detalle ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'red', color: 'white' }}>TARJETAS ROJAS</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.rojas.detalle.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}  /  {aux.minutosjugados}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                {this.props.data.penales === true ? (
                                                    <View style={{ flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>TOTAL: {this.props.data.visitante.penales.total}</Text>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>PENALES ANOTARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.penales.anotaron.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                        <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>PENALES FALLARON</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View style={{ flex: 1 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>NUMERO</Text>
                                                            </View>
                                                            <View style={{ flex: 2 }}>
                                                                <Text style={{ fontSize: '10pt', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>NOMBRE</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ margin: '5px' }}>
                                                            {this.props.data.visitante.penales.fallaron.map(aux => {
                                                                return (
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View style={{ flex: 1 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'right', marginLeft: '5px', marginRight: '5px' }}>{aux.numero}</Text>
                                                                        </View>
                                                                        <View style={{ flex: 2 }}>
                                                                            <Text style={{ fontSize: '10pt', textAlign: 'left', marginLeft: '5px', marginRight: '5px' }}>{aux.nombre}</Text>
                                                                        </View>
                                                                    </View>
                                                                )
                                                            })}
                                                        </View>
                                                    </View>
                                                ) : null}
                                                <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>FIRMA DE CAPITAN</Text>
                                                {this.props.data.visitante.acepta === 'Acepta' ? (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>ACEPTA LA VOCALIA</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE CAPITAN: {this.props.data.visitante.nombrecapitan}</Text>
                                                    </View>
                                                ) : (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO ACEPTAN VOCALIA</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE CAPITAN: {this.props.data.visitante.nombrecapitan}</Text>
                                                    </View>
                                                    
                                                )}
                                                {this.props.data.visitante.infocapitan ? (
                                                    <View style={{ margin: '7px', flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>INFORME DEL CAPITAN:</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.visitante.infocapitan}</Text>
                                                    </View>
                                                ) : null}
                                            </View>
                                        </View>
                                    </View>
                                </Page>
                                <Page size='A4'>
                                    <View style={{ flex: 1 }}>
                                        <Image style={{ margin: '10px', width: '80px', height: '30px', alignSelf: 'center' }} src={logo} />
                                        <View style={{}}>
                                            <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>INFORME VOCAL</Text>
                                            <View style={{}}>
                                                <Text style={{ margin: '5px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE VOCAL: {this.props.data.vocal}</Text>
                                                {this.props.data.tiempo ?
                                                    <View style={{ margin: '5px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>HORA DE INICIO DE PARTIDO: {this.props.data.tiempo.inicioprimertiempo}  /</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}> HORA DE FIN DE PARTIDO: {this.props.data.tiempo.finalsegundotiempo}</Text>
                                                    </View>
                                                    :
                                                    <View style={{ margin: '5px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>HORA DE INICIO DE PARTIDO:   /</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}> HORA DE FIN DE PARTIDO: </Text>
                                                    </View>
                                                }
                                                <View style={{ marginBottom: '20px' }} />
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <View style={{ margin: '5px', flex: 1, flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '14pt', fontWeight: 'bold', textAlign: 'center', marginLeft: '5px', marginRight: '5px', color: '#0A582C' }}>{this.props.data.local.equiponombre}</Text>
                                                        {this.props.data.informevocal.balonlocal === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA BALON</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA BALON</Text>
                                                        )}
                                                        {this.props.data.informevocal.cintalocal === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA CINTA DE CAPITAN</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA CINTA DE CAPITAN</Text>
                                                        )}
                                                        {this.props.data.informevocal.letrerolocal === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA LETRERO</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA LETRERO</Text>
                                                        )}
                                                    </View>
                                                    <View style={{ margin: '5px', flex: 1, flexDirection: 'column' }}>
                                                        <Text style={{ fontSize: '14pt', fontWeight: 'bold', textAlign: 'center', marginLeft: '5px', marginRight: '5px', color: '#0A582C' }}>{this.props.data.visitante.equiponombre}</Text>
                                                        {this.props.data.informevocal.balonvisitante === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA BALON</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA BALON</Text>
                                                        )}
                                                        {this.props.data.informevocal.cintavisitante === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA CINTA DE CAPITAN</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA CINTA DE CAPITAN</Text>
                                                        )}
                                                        {this.props.data.informevocal.letrerovisitante === true ? (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>SI PRESENTA LETRERO</Text>
                                                        ) : (
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NO PRESENTA LETRERO</Text>
                                                        )}
                                                    </View>

                                                </View>
                                                <View style={{ margin: '5px', flexDirection: 'column' }}>
                                                    <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: 'gray', color: 'white' }}>REGISTRO DE NOVEDADES</Text>
                                                    <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informevocal.informe}</Text>
                                                    <View style={{ marginBottom: '20px' }} />
                                                    <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>CALIFICACION JUEZ: {this.props.data.calificacionjuez}</Text>
                                                    {this.props.data.mejorequipo && this.props.data.mejorequipo !== undefined ?
                                                        <View>
                                                            <View style={{ marginBottom: '20px' }} />
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>EQUIPO FAIR-PLAY: </Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR EQUIPO: {this.props.data.mejorequipo.nombreMejorEquipo}</Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR JUGADOR: {this.props.data.mejorjugador.nombre}</Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR ARQUERO: {this.props.data.mejorarquero.nombre}</Text>
                                                            <View style={{ marginBottom: '20px' }} />
                                                        </View>
                                                        :
                                                        <View>
                                                            <View style={{ marginBottom: '20px' }} />
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>EQUIPO FAIR-PLAY: </Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR EQUIPO: </Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR JUGADOR: </Text>
                                                            <Text style={{ fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MEJOR ARQUERO: </Text>
                                                            <View style={{ marginBottom: '20px' }} />
                                                        </View>
                                                    }

                                                </View>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#b8875c', color: 'white' }}>INFORME VEEDOR</Text>
                                            <View style={{ margin: '5px', flexDirection: 'column' }}>
                                                <Text style={{ margin: '5px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px', }}>NOMBRE VEEDOR: {this.props.data.veedor}</Text>
                                                <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#b8875c', color: 'white' }}>REGISTRO DE NOVEDADES</Text>
                                                <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informeveedor}</Text>
                                                <View style={{ marginBottom: '20px' }} />

                                            </View>
                                        </View>
                                        <View style={{}}>
                                            <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>INFORME ARBITRO</Text>
                                            <View>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text style={{ margin: '5px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>NOMBRE ARBITRO: {this.props.data.arbitro.central}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                    <View style={{ margin: '5px', flex: 1, flexDirection: 'column' }}>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MARCADOR {this.props.data.local.equiponombre}: {this.props.data.informearbitro.marcadorlocal}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>GOLES</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.goleslocal}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>TARJETAS AMARILLAS</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.amarillaslocal}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>TARJETAS ROJAS</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.rojaslocal}</Text>
                                                    </View>
                                                    <View style={{ margin: '5px', flex: 1, flexDirection: 'column' }}>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>MARCADOR {this.props.data.visitante.equiponombre}: {this.props.data.informearbitro.marcadorvisitante}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>GOLES</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.golesvisitante}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>TARJETAS AMARILLAS</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.amarillasvisitante}</Text>
                                                        <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>TARJETAS ROJAS</Text>
                                                        <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.rojasvisitante}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ margin: '5px', flexDirection: 'column' }}>
                                                    <Text style={{ fontSize: '10pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#0A582C', color: 'white' }}>REGISTRO DE NOVEDADES</Text>
                                                    <Text style={{ margin: '7px', fontSize: '10pt', textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}>{this.props.data.informearbitro.informe}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text style={{ fontSize: '12pt', textAlign: 'center', fontWeight: 'bold', padding: '2px', backgroundColor: '#063652', color: 'white' }}>ESPACIO COMISION DE PENAS</Text>
                                            {this.props.data.informePenas ? (
                                                <Text style={{ fontSize: '12pt', padding: '2px' }}>{this.props.data.informePenas}</Text>
                                            ) : (
                                                <Text style={{ fontSize: '12pt', padding: '2px' }}></Text>
                                            )}

                                        </View>
                                    </View>
                                </Page>


                            </Document>
                        </PDFViewer>
                    ) : null}
                </div>
                {(this.state.mostrarHojaVocalia === true && this.state.hojaVocalia.id === this.props.data.id) ? (
                    <div >
                        <p className='TituloInformePenas'>INGRESAR INFORME DE PENAS</p>
                        <div className='divPenas'>
                            <textarea className='InformePenas'
                                placeholder='INFORME DE COMISION DE PENAS'
                                value={this.state.informePenas}
                                onChange={(e) => this.setState({ informePenas: e.target.value })}
                            />
                            <button onClick={() => this.RegistrarInforme(this.props.data)}>REGISTRAR INFORME</button>
                        </div>
                    </div>
                ) : null}
                <div className='divTextoFix'>
                    <p className='textoFix'>Fecha: {this.props.data.fecha.fecha}</p>
                    <p className='textoFix'>Grupo: {this.props.data.idgrupo}</p>
                    <p className='textoFix'> {this.props.data.etapa} / {this.props.data.modo}</p>
                    <p className='textoFix'>Arbitro: {this.props.data.arbitro.central}</p>
                    <p className='textoFix'>Vocal: {this.props.data.vocal}</p>
                    <p className='textoFix'>Veedor: {this.props.data.veedor}</p>
                </div>



            </div>
        )
    };
}
