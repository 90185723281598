import React from 'react';
import '../../estilos/fixture.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Document, Page, View, Text, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../respaldos/logo-TACTICAPP.png'
import { fire } from '../../funciones/firebase'
import { updateDoc, doc } from 'firebase/firestore';
import EscudoGenerico from '../../respaldos/icono-escudoequipo.png'

export default class JugadoresLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    fondo1 = () => {
        if (this.props.i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    render() {
        //console.log(this.props.data)
        return (
            <div style={this.fondo1()} className='tablaPosiciones'>
                
                <div className='ItemListaJugadores'>
                    <p className='ListaNumero'>{this.props.data.numero}</p>
                    <p className='ListaNombre'>{this.props.data.nombre}</p>
                    <p className='ListaId'>{this.props.data.identificacion}</p>
                </div>
                
            </div>
        )
    };
}
