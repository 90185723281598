import React from 'react';
import HomeAdmin from '../paginas/homeadmin'
import '../estilos/home.css'
import { auth } from '../funciones/firebase';
import { signInWithEmailAndPassword, onAuthStateChanged, getAuth, signOut } from 'firebase/auth'
import { fire } from '../funciones/firebase'
import { collection, query, where, getDocs, doc, onSnapshot, addDoc } from 'firebase/firestore'
import { async } from '@firebase/util';
import HomeUsuarios from '../paginas/homeusuarios'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import fondo from '../respaldos/fondo.png'
import ISesion from '../respaldos/inicia-sesion.png'

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idCampeonato: '',
      usuario: '',
      admin: '',
      pass: '',
      login: false,
      loginAdministrador: false,
      loginUsuarios: false,
      Usuario: '',
      administradores: [],
      usuarios: [],
      cargando: true,
      usua: '',
      IniciarSesion: false
    }
  }
  Login = () => {
    let email = this.state.usuario
    let pass = this.state.pass
    let administradores = this.state.administradores
    let usuarios = this.state.usuarios
    let emailok = email

    administradores.map(doc => {
      if (doc.nombreUsuario === email) {
        //console.log('UNO')
        emailok = doc.email
      }
    })
    usuarios.map(doc => {
      if (doc.nombreUsuario === email) {
        //console.log('dos')
        emailok = doc.email
      }
    })

    signInWithEmailAndPassword(auth, emailok, pass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.Iniciar(user)
        alert('INICIADO')
        this.setState({
          login: false
        })
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('DATOS ERRONEOS')
      });
  }


  getDatosInicio = async () => {
    ////console.log('user')
    ////console.log(user.uid)
    ////console.log('NOOOO')
    const ad = await query(collection(fire, "administradores"));
    const us = await query(collection(fire, "usuarios"));

    const un = onSnapshot(ad, (querySnapshot) => {
      const administradores = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        administradores.push({ id, ...data })
      });

      this.setState({
        administradores: administradores,
      })

    },
      (error) => {
        //console.log(error)
      });

    // USUARIOS
    const usu1 = await onSnapshot(us, (querySnapshot) => {
      const usuarios = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        usuarios.push({ id, ...data })
      });

      this.setState({
        usuarios: usuarios,
      })
      ////console.log(campeonatos);

    },
      (error) => {
        //console.log(error)
      });
  }

  Iniciar = async (user) => {
    let administradores = []
    let usuarios = []
    let vocales = []

    ////console.log(user.uid)

    //ADMINISTRADORES

    const ad = query(collection(fire, "administradores"));

    const un = onSnapshot(ad, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        administradores.push({ id, ...data })
      });

      ////console.log('campeonatos');
      administradores.map(admin => {
        ////console.log(admin.uidusuario)
        if (admin.uidusuario === user.uid) {
          ////console.log('ADMIN')
          ////console.log(admin.uidusuario)
          this.setState({
            admin: admin,
            administradores: administradores,
            loginAdministrador: true,
            loginUsuarios: false,
            login: false,
            cargando: false,
            usuario: '',
            pass: '',
            Usuario: admin
          })
        }

      })

    },
      (error) => {
        //console.log(error)
      });

    // USUARIOS
    const us = query(collection(fire, "usuarios"));

    const usu1 = onSnapshot(us, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        usuarios.push({ id, ...data })
      });

      ////console.log(campeonatos);
      usuarios.map(usua => {
        ////console.log(usua.uidusuario)
        if (usua.uidusuario === user.uid) {
          ////console.log('USUARIO')
          ////console.log(usua.uidusuario)
          ////console.log(user.uid)
          this.setState({
            usua: usua,
            usuarios: usuarios,
            loginAdministrador: false,
            loginUsuarios: true,
            login: false,
            cargando: false,
            usuario: '',
            pass: '',
            Usuario: usua
          })
        }
      })

    },
      (error) => {
        //console.log(error)
      });

    //VOCALES
    const vo = query(collection(fire, "vocales"));

    const usu2 = onSnapshot(vo, (querySnapshot) => {

      querySnapshot.forEach((doc) => {

        const id = doc.id;
        const data = doc.data();
        vocales.push({ id, ...data })
      });

      ////console.log(vocales);
      vocales.map(voca => {
        if (voca.uidusuario === user.uid) {
          //console.log('OK')
          alert('USUARIO NO PERMITIDO')
          this.CerrarSesion()
          this.setState({
            cargando: false
          })

        }
      })

    },
      (error) => {
        //console.log(error)
      });


  }

  CerrarSesion = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      //console.log('CHAO')
      this.setState({
        login: true
      })
    }).catch((error) => {
      // An error happened.
    });
  }

  componentDidMount() {

    onAuthStateChanged(auth, (user) => {
      if (user) {
        ////console.log(user)
        this.Iniciar(user)
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        //const uid = user.uid;
        ////console.log(user)
        // ...
      } else {
        // User is signed out
        this.CerrarSesion()
        this.getDatosInicio()
        this.setState({
          loginAdministrador: false,
          loginUsuarios: false,
          login: true,
          cargando: false
        })
        //console.log('INICIA SESION')
        // ...
      }
    });
  }

  IniciarSesion = () => {
    this.setState({
      IniciarSesion: !this.state.IniciarSesion
    })
  }

  PoliticaPrivacidad = () =>{

  }

  render() {

    //console.log('HOLA')
    return (
      <div>
        {this.state.login === true ? (
          <div className='General' style={{ backgroundImage: `url(${fondo})`, backgroundSize: 'cover' }}>
            <div className='Header'>
              <img className='imagen' src={require('../respaldos/FUTBOLISTA.png')} />
            </div>
            {this.state.IniciarSesion === false ?
              <div className='Body'>

                <img className='imagen1' src={require('../respaldos/tacticapp.png')} />
                <input className='imagen2' type='button' onClick={this.IniciarSesion} style={{ backgroundImage: `url(${ISesion})`, backgroundSize: 'cover' }} />
                <img className='imagen1' src={require('../respaldos/publi-1.png')} />
                <img className='imagen1' src={require('../respaldos/contactos.png')} />
                <img className='imagen1' src={require('../respaldos/publi-2.png')} />
                <a className='imagen3' href="https://sites.google.com/view/politica-privacidad-tacticapp/privacidad">Política de Privacidad</a>
              </div>
              :
              <div className='Body1'>

                <p className='subInicio'>INICIA SESIÓN</p>
                <input className='inputInicio' placeholder='USUARIO' value={this.state.usuario} onChange={(e) => this.setState({ usuario: e.target.value })} />
                <input className='inputInicio' placeholder='PASSWORD' value={this.state.pass} onChange={(e) => this.setState({ pass: e.target.value })} />
                <button className='botonInicio' onClick={this.Login} >INICIAR SESION</button>
                <input className='botonRegresa' value={'REGRESA'} type='button' onClick={this.IniciarSesion} />
              </div>
            }
          </div>
        ) : (
          null
        )}

        {this.state.loginAdministrador === true ? (
          <HomeAdmin CerrarSesion={this.CerrarSesion}
            Usuario={this.state.Usuario} />
        ) : (
          <p></p>
        )}
      </div>
    )
  };
}
