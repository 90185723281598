import { initializeApp, firebase } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage, ref as sRef } from 'firebase/storage'
import {getAuth} from 'firebase/auth'
import { getFunctions } from 'firebase/functions';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyD_vri44fVQ9PTIn_QatCXR4afuiOk2Ui0",
    authDomain: "campeondatos-e55b3.firebaseapp.com",
    databaseURL: "https://campeondatos-e55b3.firebaseio.com",
    projectId: "campeondatos-e55b3",
    storageBucket: "campeondatos-e55b3.appspot.com",
    messagingSenderId: "884094548282",
    appId: "1:884094548282:web:46e24ab5aec943f2a23803",
    measurementId: "G-GFBEC4ZSDB"
  };
  
const app = initializeApp(firebaseConfig)

export const fire = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)
export const fun = getFunctions(app)

export default app