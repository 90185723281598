import React from 'react';
import './App.css';
import Home from '../src/paginas/home'

export default class App extends React.Component {

  render() {
    return (
      <div style={{backgroundColor: 'black'}}>
        <Home />
      </div>
    )
  };
}