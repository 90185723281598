import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import '../../estilos/tablaposiciones.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import EscudoGenerico from '../../respaldos/icono-escudoequipo.png'

export default class TablaPosiciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fondoGrupal: '',
            fondoAcumulada: '',
            fondoClasificatoria: '',
            bannerGrupal: '',
            bannerAcumulada: '',
            bannerClasificatoria: '',
            colorTituloNombre: { color: '#063652' },
            colorTitulo: { color: 'black' },
            pieImagen: '../../respaldos/pie-de-imagen-TP.png',
            colorLetraTabla: { color: 'black' },
            PNGgrupo: '',
            PNGAcumulada: '',
            PNGclasificatoria: '',
            JPGGrupo: '',
            JPGAcumulada: '',
            JPGclasificatoria: '',
            fondoTP: null,
            bannerbajoTP: null
        }
    }

    componentDidMount() {
        this.traerFondo()
        this.traerBannerBajo()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.idCampeonato !== this.props.idCampeonato) {
            // ... do something
            this.traerFondo()
            this.traerBannerBajo()
        }
    }

    fondo = (i) => {
        let aux = this.props.catGrupos.length
        if (i < aux) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 2) - 1 && i < (aux * 3)) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 4) - 1 && i < (aux * 5)) {
            return { backgroundColor: '#E4ECE7' }
        }

    }

    fondo1 = (i) => {
        let aux = this.props.catGrupos.length
        if (i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    traerFondo = async () => {
        ////console.log(fondo)
        this.setState({
            fondoGrupal: fondo,
            fondoAcumulada: fondo,
            fondoClasificatoria: fondo
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then(doc => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    fondoGrupal: doc,
                    fondoAcumulada: doc,
                    fondoClasificatoria: doc
                })
            }

        }
        ).catch(e => {
            //console.log(e)
        })
    }

    traerBannerBajo = async () => {
        ////console.log(fondo)
        this.setState({
            bannerGrupal: banner,
            bannerAcumulada: banner,
            bannerClasificatoria: banner
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    bannerGrupal: doc,
                    bannerAcumulada: doc,
                    bannerClasificatoria: doc,
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    descargarPngGrupos = () => {
        let node = document.getElementById('tablagruposGeneral')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGgrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGGrupos = () => {
        let node = document.getElementById('tablagruposGeneral')
        //console.log(node)
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGGrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngGAcumulada = () => {
        //console.log('HOLA')
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGAcumulada: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGAcumulada = () => {
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGAcumulada: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngGClasificatoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                img.crossOrigin = 'Anonymous'
                this.setState({
                    PNGclasificatoria: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGClasificatoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGclasificatoria: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    SubirFondo = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('FondoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    fondoTP: null
                })
            )
            this.traerFondo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    SubirBanerTP = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('BannerBajoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    bannerbajoTP: null
                })
            )
            this.traerBannerBajo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    elegirFoto = async (e) => {
        ////console.log(e.target.id)
        let idAux = e.target.id
        let foto = e.target.files[0]

        ////console.log(foto)
        let fotoOk = new FileReader()
        let url = fotoOk.readAsDataURL(foto)
        ////console.log(url)
        fotoOk.onloadend = function (e) {
            if (idAux === 'FondoTP') {
                this.setState({
                    fondoTP: [fotoOk.result]
                })
            }
            if (idAux === 'BannerBajoTP') {
                this.setState({
                    bannerbajoTP: [fotoOk.result]
                })
            }
        }.bind(this);

    }

    render() {
        
        return (
            <div className='tablaPosiciones'>
                 
                {this.props.mostrarEtapa === true && (
                    <div className='tablaPosiciones'>
                        <div id='tablagruposGeneral' 
                        style={{
                            backgroundImage: `url(${this.state.fondoGrupal})`,
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }} 
                        className='tablaPosiciones1'>
                            <div className='tituloTabla1'>
                                <p style={this.state.colorTituloNombre} className='tituloTabla'>{this.props.Campeonato.nombre}</p>
                                <p style={this.state.colorTitulo} className='tituloTabla'>{this.props.eleccionMenu}  -  {this.props.categoriaElegida}  -  GRUPO: {this.props.grupoElegido}</p>
                                <p style={this.state.colorTituloNombre} className='tituloTabla'>{this.props.etapaSeleccionada}</p>
                            </div>
                            <table style={this.state.colorLetraTabla} id='tablagrupos' className='tabla'>
                                <thead>
                                    <tr className='filaTitulos'>
                                        <th className='celdas1'>Pos.</th>
                                        <th className='celdas1'>NOMBRE</th>
                                        <th className='celdas1'>PTS.</th>
                                        <th className='celdas1'>G.D.</th>
                                        <th className='celdas1'>P.J.</th>
                                        <th className='celdas1'>P.G.</th>
                                        <th className='celdas1'>P.E.</th>
                                        <th className='celdas1'>P.P.</th>
                                        <th className='celdas1'>G.F.</th>
                                        <th className='celdas1'>G.C.</th>
                                    </tr>
                                </thead>
                                {this.props.equiposGrupos.map((doc, i) => {
                                    return (
                                        <tbody key={i}>
                                            <tr className='filaUno' key={i} style={this.fondo1(i)}>
                                                <td className='celdas2'>{i + 1}</td>
                                                <tr className='nombrescudo'>
                                                {doc.escudo ? 
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={doc.escudo} />
                                :
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
                                }
                                                <td style={{ width: '100%', flex: 5 }} className='nombrequipo'>{doc.nombre}</td>
                                                </tr>
                                                <td className='celdas2'>{doc.puntos}</td>
                                                <td className='celdas2'>{doc.diferencia}</td>
                                                <td className='celdas2'>{doc.jugados}</td>
                                                <td className='celdas2'>{doc.ganados}</td>
                                                <td className='celdas2'>{doc.empatados}</td>
                                                <td className='celdas2'>{doc.perdidos}</td>
                                                <td className='celdas2'>{doc.favor}</td>
                                                <td className='celdas2'>{doc.contra}</td>
                                            </tr>
                                        </tbody>
                                    )
                                })}
                            </table>
                            <br />

                            <div className='LogoHeader'>
                                <img className='bannerPie' src={this.state.bannerGrupal} />
                            </div>
                        </div>
                        <div className='DivBotones'>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.PNGgrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                    <img src={this.state.PNGgrupo} width="250" />
                                </a>
                                <button onClick={() => this.descargarPngGrupos()} className='botonesExtras'>DESCARGAR PNG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.JPGGrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                    <img src={this.state.JPGGrupo} width="250" />
                                </a>
                                <button onClick={() => this.descargarJPGGrupos()} className='botonesExtras'>DESCARGAR JPEG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga'>
                                </a>
                                <ReactHTMLTableToExcel
                                    id='BotonExportarGrupos'
                                    table='tablagrupos'
                                    filename={`T.P. Categoria ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}
                                    sheet={`GRUPO ${this.props.idgrupos}`}
                                    ButtonText='EXPORTAR TABLA'
                                    className='botonesExtras'
                                    buttonText='DESCARGAR EXCEL'
                                />
                            </div>
                        </div>
                    </div>
                )}

                {this.props.mostrarAcumulada === true && (
                    <div className='tablaPosiciones1'>
                        <div id='tablaAcumulada' style={{
                            backgroundImage: `url(${this.state.fondoAcumulada})`,
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }} className='tablaPosiciones1'>
                            <div className='tituloTabla1'>
                                <p className='tituloTabla'>{this.props.eleccionMenu}  -  {this.props.categoriaElegida}  -  GRUPO: {this.props.grupoElegido}</p>
                            </div>
                            <table style={this.state.colorLetraTabla} id='tablacategoria' className='tabla'>
                                <thead>
                                <tr className='filaTitulos'>
                                    <th className='celdas1'>Pos.</th>
                                    <th className='celdas1'>NOMBRE</th>
                                    <th className='celdas1'>PTS.</th>
                                    <th className='celdas1'>G.D.</th>
                                    <th className='celdas1'>P.J.</th>
                                    <th className='celdas1'>P.G.</th>
                                    <th className='celdas1'>P.E.</th>
                                    <th className='celdas1'>P.P.</th>
                                    <th className='celdas1'>G.F.</th>
                                    <th className='celdas1'>G.C.</th>
                                </tr>
                                </thead>
                                {this.props.equiposCategoria.map((doc, i) => {
                                    return (
                                        <tbody key={i}>
                                            <tr className='filaUno' key={i} style={this.fondo1(i)}>
                                                <td className='celdas2'>{i + 1}</td>
                                                <tr className='nombrescudo'>
                                                {doc.escudo ? 
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={doc.escudo} />
                                :
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
                                }
                                                <td style={{ width: '100%', flex: 5 }} className='nombrequipo'>{doc.nombre}</td>
                                                </tr>
                                                <td className='celdas2'>{doc.puntos}</td>
                                                <td className='celdas2'>{doc.diferencia}</td>
                                                <td className='celdas2'>{doc.jugados}</td>
                                                <td className='celdas2'>{doc.ganados}</td>
                                                <td className='celdas2'>{doc.empatados}</td>
                                                <td className='celdas2'>{doc.perdidos}</td>
                                                <td className='celdas2'>{doc.favor}</td>
                                                <td className='celdas2'>{doc.contra}</td>
                                            </tr>
                                        </tbody>
                                    )
                                })}

                            </table>
                            <div className='LogoHeader'>
                                <img className='bannerPie' src={this.state.bannerAcumulada} />
                            </div>
                        </div>
                        <br />
                        <div className='DivBotones'>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.PNGAcumulada} download={`TP Acumulada Cat ${this.props.idcategoria}`}>
                                    <img src={this.state.PNGAcumulada} width="250" />
                                </a>
                                <button onClick={() => this.descargarPngGAcumulada()} className='botonesExtras'>DESCARGAR PNG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.JPGAcumulada} download={`TP Acumulada Cat ${this.props.idcategoria}`}>
                                    <img src={this.state.JPGAcumulada} width="250" />
                                </a>
                                <button onClick={() => this.descargarJPGAcumulada()} className='botonesExtras'>DESCARGAR JPEG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga'>
                                </a>
                                <ReactHTMLTableToExcel
                                    id='BotonExportarAcumulada'
                                    table='tablacategoria'
                                    filename={`tabla Posiciones Acumulada Categoria ${this.props.idcategoria}`}
                                    sheet={`CATEGORIA ${this.props.idcategoria}`}
                                    ButtonText='EXPORTAR TABLA'
                                    className='botonesExtras'
                                    buttonText='DESCARGAR EXCEL'
                                />
                            </div>
                        </div>

                    </div>
                )}
                {this.props.mostrarClasificatoria === true && (
                    <div className='tablaPosiciones1'>
                        <div id='tablaClasificatoria' style={{
                            backgroundImage: `url(${this.state.fondoAcumulada})`,
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }} className='tablaPosiciones1'>
                            <div className='tituloTabla1'>
                                <p className='tituloTabla'>{this.props.eleccionMenu}  -  {this.props.categoriaElegida}  -  GRUPO: {this.props.grupoElegido}</p>
                            </div>
                            <table style={this.state.colorLetraTabla} id='tablaclasificacion' className='tabla'>
                                <thead>
                                <tr className='filaTitulos'>
                                    <th className='celdas1'>Pos.</th>
                                    <th className='celdas1'>NOMBRE</th>
                                    <th className='celdas1'>PTS.</th>
                                    <th className='celdas1'>G.D.</th>
                                    <th className='celdas1'>P.J.</th>
                                    <th className='celdas1'>P.G.</th>
                                    <th className='celdas1'>P.E.</th>
                                    <th className='celdas1'>P.P.</th>
                                    <th className='celdas1'>G.F.</th>
                                    <th className='celdas1'>G.C.</th>
                                </tr>
                                </thead>
                                {this.props.equiposUnidos.map((doc, i) => {
                                    return (
                                        <tbody key={i}>
                                            <tr key={i} style={this.fondo(i)}>
                                                <td className='celdas2'>{i + 1}</td>
                                                <tr className='nombrescudo'>
                                                {doc.escudo ? 
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={doc.escudo} />
                                :
                                <img style={{flex:1}} className='fotoEscudo1' alt='fotoEscudo' src={EscudoGenerico} />
                                }
                                                <td style={{ width: '100%', flex: 5 }} className='nombrequipo'>{doc.nombre}</td>
                                                </tr>
                                                
                                                <td className='celdas2'>{doc.puntos}</td>
                                                <td className='celdas2'>{doc.diferencia}</td>
                                                <td className='celdas2'>{doc.jugados}</td>
                                                <td className='celdas2'>{doc.ganados}</td>
                                                <td className='celdas2'>{doc.empatados}</td>
                                                <td className='celdas2'>{doc.perdidos}</td>
                                                <td className='celdas2'>{doc.favor}</td>
                                                <td className='celdas2'>{doc.contra}</td>
                                            </tr>
                                        </tbody>
                                    )
                                })}

                            </table>
                            <div className='LogoHeader'>
                                <img className='bannerPie' src={this.state.bannerClasificatoria} />
                            </div>
                        </div>
                        <br />
                        {this.props.usuario !== 'usuario' ?
                        <div className='DivBotones'>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.PNGclasificatoria} download={`TP Clasificatoria Cat ${this.props.idcategoria}`}>
                                    <img src={this.state.PNGclasificatoria} width="250" />
                                </a>
                                <button onClick={() => this.descargarPngGClasificatoria()} className='botonesExtras'>DESCARGAR PNG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.JPGclasificatoria} download={`TP Clasificatoria Cat ${this.props.idcategoria}`}>
                                    <img src={this.state.JPGclasificatoria} width="250" />
                                </a>
                                <button onClick={() => this.descargarJPGClasificatoria()} className='botonesExtras'>DESCARGAR JPEG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga'>
                                </a>
                                <ReactHTMLTableToExcel
                                    id='BotonExportarClasificacion'
                                    table='tablaclasificacion'
                                    filename={`T. Clasificacion por Posiciones Categoria ${this.props.idcategoria}`}
                                    sheet={`CATEGORIA ${this.props.idcategoria}`}
                                    ButtonText='EXPORTAR TABLA'
                                    className='botonesExtras'
                                    buttonText='DESCARGAR EXCEL'
                                />
                            </div>
                        </div>
                        : null }
                    </div>
                )}
                <div className='FicherosSubir'>
                    <div className='Fichero'>
                        <img src={this.state.fondoTP} style={{ margin: 10 }} width="70" />
                        <input onChange={(e) => this.elegirFoto(e)} id='FondoTP' type='file' />
                        <button onClick={this.SubirFondo} className='botonesExtras'>SUBIR FONDO T.P.</button>
                    </div>
                    <div className='Fichero'>
                        <img src={this.state.bannerbajoTP} style={{ margin: 10 }} width="70" />
                        <input onChange={(e) => this.elegirFoto(e)} id='BannerBajoTP' type='file' />
                        <button onClick={this.SubirBanerTP} className='botonesExtras'>SUBIR BANNER BAJO T.P.</button>
                    </div>
                </div>
                
            </div>
        )
    };
}
