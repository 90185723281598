import React, {ReactPropTypes} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import '../../estilos/fixture.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Document, Page, View, Text, PDFViewer, Image, TextInput } from '@react-pdf/renderer';
import logo from '../../respaldos/logo-TACTICAPP.png'
import { async } from '@firebase/util';
import { fire } from '../../funciones/firebase'
import { updateDoc, doc } from 'firebase/firestore';
import FixtureFecha from './fixtureFecha';
import FlatList from 'flatlist-react';


export default class FixtureGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarFecha: false,
            fechaElegida: '',

            fondoGrupal: '',
            fondoAcumulada: '',
            fondoClasificatoria: '',
            bannerGrupal: '',
            bannerAcumulada: '',
            bannerClasificatoria: '',
            colorTitulo: { color: 'black' },
            colorTituloNombre: { color: '#063652' },
            pieImagen: '../../respaldos/pie-de-imagen-TP.png',
            prueba: '../../respaldos/logo-TACTICAPP.png',
            colorLetraTabla: { color: 'black' },
            PNGgrupo: '',
            PNGAcumulada: '',
            PNGclasificatoria: '',
            JPGGrupo: '',
            JPGAcumulada: '',
            JPGclasificatoria: '',
            fondoTP: null,
            bannerbajoTP: null,

            fixtureFecha: [],
            mostrarHojaVocalia: false,
            mostrarHojaVocaliaindex: null,
            mostrarHojaVocaliaGeneral: false,
            hojaVocalia: {},
            informePenas: '',
            informePenasok: '',
            aux: false,
            escudos: ['1', '2', '3'],
            mostrarEtapa: false,
            auxi: 0
        }
        
    }

    componentDidMount() {
        this.traerFondo()
        this.traerBannerBajo()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps !== this.props){
            let doc = this.state.fechaElegida
            
            this.mostrarFecha2(doc)
            this.traerFondo()
            this.traerBannerBajo()
        }
    }

    fondo = (i) => {
        let aux = this.props.catGrupos.length
        if (i < aux) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 2) - 1 && i < (aux * 3)) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 4) - 1 && i < (aux * 5)) {
            return { backgroundColor: '#E4ECE7' }
        }

    }

    fondo1 = (i) => {
        let aux = this.props.catGrupos.length
        if (i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    traerFondo = async () => {
        this.setState({
            fondoGrupal: fondo,
            fondoAcumulada: fondo,
            fondoClasificatoria: fondo
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)

        let fondoFire = await getDownloadURL(referencia).then(doc => {
            if (doc) {
                this.setState({
                    fondoGrupal: doc,
                    fondoAcumulada: doc,
                    fondoClasificatoria: doc
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    traerBannerBajo = async () => {
        this.setState({
            bannerGrupal: banner,
            bannerAcumulada: banner,
            bannerClasificatoria: banner
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)

        let fondoFire = await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                this.setState({
                    bannerGrupal: doc,
                    bannerAcumulada: doc,
                    bannerClasificatoria: doc,
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    descargarPngFixtureGeneral = () => {
        let node = document.getElementById('fixtureGeneral')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                //var img = new Image();
                //img.src = dataUrl;
                this.setState({
                    PNGgrupo: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGFixtureGeneral = () => {       
        let node = document.getElementById('fixtureGeneral')

        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                //var img = new Image();
                //img.src = dataUrl;
                this.setState({
                    JPGGrupo: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngVocalia = () => {
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGAcumulada: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGVocalia = () => {
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGAcumulada: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngFixtureCategoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                img.crossOrigin = 'Anonymous'
                this.setState({
                    PNGclasificatoria: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGFixtureCategoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGclasificatoria: dataUrl
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    SubirFondo = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('FondoTP').files[0]

        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    fondoTP: null
                })
            )
            this.traerFondo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    SubirBanerTP = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('BannerBajoTP').files[0]

        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    bannerbajoTP: null
                })
            )
            this.traerBannerBajo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    elegirFoto = async (e) => {
        let idAux = e.target.id
        let foto = e.target.files[0]

        let fotoOk = new FileReader()
        let url = fotoOk.readAsDataURL(foto)

        fotoOk.onloadend = function (e) {
            if (idAux === 'FondoTP') {
                this.setState({
                    fondoTP: [fotoOk.result]
                })
            }
            if (idAux === 'BannerBajoTP') {
                this.setState({
                    bannerbajoTP: [fotoOk.result]
                })
            }
        }.bind(this);

    }

    mostrarFecha = (doc) => {
        let FixtureGeneral = this.props.fixtureok
        let fixture = []
        this.setState({
            mostrarFecha: true,
            fechaElegida: doc
        })
        if (doc === this.state.fechaElegida) {
            if(this.state.aux === false){
                this.setState({
                    mostrarFecha: false,
                    fechaElegida: ''
                })
            }
        }
        FixtureGeneral.map(aux => {
            if (aux.numerofecha === doc.fecha) {
                fixture.push(aux)
            }
        })
        fixture.sort((a, b) => {
            if (a.fecha.dia > b.fecha.dia) {
                return -1
            }
            if (a.fecha.dia < b.fecha.dia) {
                return 1
            } else {
                if (a.fecha.ordenar < b.fecha.ordenar) {
                    return -1
                }
                if (a.fecha.ordenar > b.fecha.ordenar) {
                    return 1
                }
                return 0
            }
            return 0
        })
        this.setState({
            fixtureFecha: fixture
        })
    }

    mostrarFecha2 = (doc) => {
        let FixtureGeneral = this.props.fixtureok
        let fixture = []
        this.setState({
            mostrarFecha: true,
        })

        FixtureGeneral.map(aux => {
            if (aux.numerofecha === doc.fecha) {
                fixture.push(aux)
            }
        })
        fixture.sort((a, b) => {
            if (a.fecha.dia > b.fecha.dia) {
                return -1
            }
            if (a.fecha.dia < b.fecha.dia) {
                return 1
            } else {
                if (a.fecha.ordenar < b.fecha.ordenar) {
                    return -1
                }
                if (a.fecha.ordenar > b.fecha.ordenar) {
                    return 1
                }
                return 0
            }
            return 0
        })
        this.setState({
            fixtureFecha: fixture
        })
        
    }

    RevisarVocalia = (doc, i) => {
        if(i === null){
            this.setState({
                mostrarHojaVocalia: false,
                mostrarHojaVocaliaindex: i,
                hojaVocalia: doc
            })
        } else{
            this.setState({
                mostrarHojaVocalia: true,
                mostrarHojaVocaliaindex: i,
                hojaVocalia: doc
            })
        }
        
    }

    mostrarHojaVocaliaGeneral=()=>{
        this.setState({
            mostrarHojaVocaliaGeneral: !this.state.mostrarHojaVocaliaGeneral
        })
    }

    RegistrarInforme=async(aux)=>{
        let id = this.props.idCampeonato
        let cat = this.props.categoriaElegida
        let eta = this.props.etapaSeleccionada
        let partido = aux.id
        let informePenas = this.state.informePenas
        const Informacion = doc(fire, "listacampeonatos", `${id}`, "fixture", `${partido}`)
        await updateDoc(Informacion, {
            informePenas: informePenas,
          }).then(()=>{
              alert('REGISTRADO')
              this.setState({
                  aux: true
              })
              this.props.SepararCategoriasFixture(cat,eta)
          })
    }



    render() {
        let claseListaEquipos = 'listaEquiposA'
        if (this.state.mostrarFecha === true) {
            claseListaEquipos = 'listaEquiposPeque'
        }
        return (
            <div className='tablaPosiciones'>
                <div className={claseListaEquipos}>
                    
                    {this.props.fechas && this.props.fechas.map((doc, i) => {
                        let clase = 'Fechas'
                        let claseNombre = 'nombreFecha'
                        if (this.state.mostrarFecha === true) {
                            clase = 'FechasPeques'
                            claseNombre = 'nombreFechaPeque'
                        }
                        return (
                            <button onClick={() => this.mostrarFecha(doc)} className={clase}>
                                {doc.fecha ? (
                                    <a className={claseNombre}>FECHA {doc.fecha}</a>
                                ) : (
                                    <a className={claseNombre}>{doc.instancia}</a>
                                )}

                            </button>
                        )
                    })} 
                    
                </div>
                {this.props.usuario !== 'usuario' ?
                <div className='DivBotones'>
                    <button onClick={this.mostrarHojaVocaliaGeneral}>PRESIONA PARA REVISAR HOJAS DE VOCALIA</button>
                    {/*<button onClick={this.mostrarHojaVocaliaGeneral}>VER EL CONSOLIDADO DE TODAS LAS VOCALIAS</button>
                    */}
                </div>
                : null}
                
                {this.props.mostrarEtapa === true && (
                    <div id='tablaPosiciones' className='tablaPosiciones'>
                        <div id='fixtureGeneral' style={{
                            backgroundImage: `url(${this.state.fondoGrupal})`,
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }} className='tablaPosiciones1'>
                            <div className='tituloTabla1'>
                                <p style={this.state.colorTituloNombre} className='tituloTabla'>{this.props.Campeonato.nombre}</p>
                                <p style={this.state.colorTitulo} className='tituloTabla'>{this.props.eleccionMenu}  -  {this.props.categoriaElegida}</p>
                            </div>
                            <div className='bodyEquipos'>
                            

                                {this.state.mostrarFecha === true ? (
                                    <div className='muestraEquipo'>
                                        {this.state.fechaElegida ? (
                                            <div className='fixture1'>

                                                <div className='divTitulo'>
                                                    <p className='Titulo'>FECHA: {this.state.fechaElegida.fecha}</p>
                                                </div>
                                                <div className='fixtureTitulos'>
                                                    <div className='dia'>
                                                        <p className='SubtituloFix'>DIA</p>
                                                    </div>
                                                    <div className='hora'>
                                                        <p className='SubtituloFix'>HORA</p>
                                                    </div>
                                                    <div className='partido'>
                                                        <p className='SubtituloFix'>PARTIDO</p>
                                                    </div>
                                                    <div className='estadio'>
                                                        <p className='SubtituloFix'>CATEGORIA</p>
                                                    </div>
                                                    <div className='cancha'>
                                                        <p className='SubtituloFix'>CANCHA</p>
                                                    </div>
                                                    <div className='estadio'>
                                                        <p className='SubtituloFix'>ESTADIO</p>
                                                    </div>
                                                    
                                                    
                                                    
                                                </div>
                                                <ul style={{padding: 0}} className='tablaPosiciones'>
                                                    <FlatList list={this.state.fixtureFecha}
                                                    renderItem={(item, index) => <FixtureFecha key={item.key} data={item}
                                                        Campeonato={this.props.Campeonato}
                                                        i={index}
                                                        idCampeonato={this.props.idCampeonato}
                                                        grupoElegido={this.props.grupoElegido}
                                                        mostrarHojaVocaliaGeneral={this.state.mostrarHojaVocaliaGeneral}
                                                        fechaElegida={this.state.fechaElegida}
                                                        SepararCategoriasFixture={this.props.SepararCategoriasFixture}
                                                        eligeCategoria2={this.props.eligeCategoria2}
                                                        etapaSeleccionada={this.props.etapaSeleccionada}
                                                        mostrarFecha2={this.mostrarFecha2}
                                                        categoriaElegida={this.props.categoriaElegida}
                                                        />}
                                                    renderWhenEmpty={() => <div>Lista Vacia!</div>} />
                                                </ul>
                                                
    
                                            </div>
                                        ) : null}

                                    </div>
                                ) : (null)}
                            </div>
                            <div className='LogoHeader'>
                                <img className='bannerPie' src={this.state.bannerGrupal} />
                            </div>
                        </div>
                        {this.props.usuario !== 'usuario' ?
                        <div id='BotonesPrueba' className='DivBotones'>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.PNGgrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                    <img src={this.state.PNGgrupo} width="250" />
                                </a>
                                <button onClick={() => this.descargarPngFixtureGeneral()} className='botonesExtras'>DESCARGAR PNG</button>
                            </div>
                            <div className='BotonesDescarga'>
                                <a className='ImagenDescarga' href={this.state.JPGGrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                    <img src={this.state.JPGGrupo} width="250" />
                                </a>
                                <button onClick={() => this.descargarJPGFixtureGeneral()} className='botonesExtras'>DESCARGAR JPEG</button>
                            </div>
                            
                        </div>
                        : null}
                        <div className='FicherosSubir'>
                            <div className='Fichero'>
                                <img src={this.state.fondoTP} style={{ margin: 10 }} width="70" />
                                <input onChange={(e) => this.elegirFoto(e)} id='FondoTP' type='file' />
                                <button onClick={this.SubirFondo} className='botonesExtras'>SUBIR FONDO T.P.</button>
                            </div>
                            <div className='Fichero'>
                                <img src={this.state.bannerbajoTP} style={{ margin: 10 }} width="70" />
                                <input onChange={(e) => this.elegirFoto(e)} id='BannerBajoTP' type='file' />
                                <button onClick={this.SubirBanerTP} className='botonesExtras'>SUBIR BANNER BAJO T.P.</button>
                            </div>
                        </div>
                        

                    </div>
                )}

            </div>
        )
    };
}
