import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import '../../estilos/equipos.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import fotoEscudo from '../../respaldos/icono-escudoequipo.png'
import { collection, query, where, getDocs, doc, onSnapshot, addDoc } from 'firebase/firestore'
import { fire } from '../../funciones/firebase'
import JugadoresLayout from './jugadoresLayout';
import FlatList from 'flatlist-react';

export default class ListaEquipos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fondoGrupal: '',
            fondoAcumulada: '',
            fondoClasificatoria: '',
            bannerGrupal: '',
            bannerAcumulada: '',
            bannerClasificatoria: '',
            colorTitulo: { color: 'black' },
            pieImagen: '../../respaldos/pie-de-imagen-TP.png',
            colorLetraTabla: { color: 'black' },
            PNGgrupo: '',
            PNGAcumulada: '',
            PNGclasificatoria: '',
            JPGGrupo: '',
            JPGAcumulada: '',
            JPGclasificatoria: '',
            fondoTP: null,
            bannerbajoTP: null,
            estiloBoton: {},
            mostrarEquipo: false,
            equipoElegido: {},
            mostrarDatosEquipo: true,
            ListaEquipos: true,
            jugadores: []
        }
    }

    componentDidMount() {
        this.traerFondo()
        this.traerBannerBajo()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.idCampeonato !== this.props.idCampeonato) {
            // ... do something
            this.traerFondo()
            this.traerBannerBajo()
        }
    }

    fondo = (i) => {
        let aux = this.props.catGrupos.length
        if (i < aux) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 2) - 1 && i < (aux * 3)) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 4) - 1 && i < (aux * 5)) {
            return { backgroundColor: '#E4ECE7' }
        }

    }

    fondo1 = (i) => {
        let aux = this.props.catGrupos.length
        if (i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    traerFondo = async () => {
        ////console.log(fondo)
        this.setState({
            fondoGrupal: fondo,
            fondoAcumulada: fondo,
            fondoClasificatoria: fondo
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then(doc => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    fondoGrupal: doc,
                    fondoAcumulada: doc,
                    fondoClasificatoria: doc
                })
            }

        }
        ).catch(e => {
            //console.log(e)
        })
    }

    traerBannerBajo = async () => {
        ////console.log(fondo)
        this.setState({
            bannerGrupal: banner,
            bannerAcumulada: banner,
            bannerClasificatoria: banner
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    bannerGrupal: doc,
                    bannerAcumulada: doc,
                    bannerClasificatoria: doc,
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    descargarPngGrupos = () => {
        let node = document.getElementById('tablagruposGeneral')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGgrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGGrupos = () => {
        let node = document.getElementById('tablagruposGeneral')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGGrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngGAcumulada = () => {
        //console.log('HOLA')
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGAcumulada: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGAcumulada = () => {
        let node = document.getElementById('tablaAcumulada')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGAcumulada: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarPngGClasificatoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                img.crossOrigin = 'Anonymous'
                this.setState({
                    PNGclasificatoria: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGClasificatoria = () => {
        let node = document.getElementById('tablaClasificatoria')
        domtoimage.toJpeg(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    JPGclasificatoria: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    SubirFondo = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('FondoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    fondoTP: null
                })
            )
            this.traerFondo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    SubirBanerTP = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('BannerBajoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    bannerbajoTP: null
                })
            )
            this.traerBannerBajo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    elegirFoto = async (e) => {
        ////console.log(e.target.id)
        let idAux = e.target.id
        let foto = e.target.files[0]

        ////console.log(foto)
        let fotoOk = new FileReader()
        let url = fotoOk.readAsDataURL(foto)
        ////console.log(url)
        fotoOk.onloadend = function (e) {
            if (idAux === 'FondoTP') {
                this.setState({
                    fondoTP: [fotoOk.result]
                })
            }
            if (idAux === 'BannerBajoTP') {
                this.setState({
                    bannerbajoTP: [fotoOk.result]
                })
            }
        }.bind(this);

    }

    mostrarEquipo = async (doc) => {
        //console.log(doc)
        let id = doc.id
        let equipoElegido = this.state.equipoElegido
        
        if (doc === equipoElegido) {
            this.setState({
                mostrarEquipo: false,
                equipoElegido: doc,
                ListaEquipos: true
            })
        } else {
            this.setState({
                mostrarEquipo: true,
                equipoElegido: doc,
                ListaEquipos: false
            })

            const q = query(collection(fire, "jugadores"), where('idequipo', '==', `${id}`));

            await onSnapshot(q, (querySnapshot) => {
                const jugadores = [];
                ////console.log(querySnapshot);
                querySnapshot.forEach((doc) => {
                    const id = doc.id;
                    const data = doc.data();
                    jugadores.push({ id, ...data })
                });
                //console.log(jugadores.length);
                jugadores.sort(function (a, b) {
                    
                    return a.numero-b.numero;
                  });
                this.setState({
                    jugadores: jugadores
                })
            },
                (error) => {
                    //console.log(error)
                });
        }
        
    }

    mostrarDatosEquipo = () => {
        this.setState({
            mostrarDatosEquipo: !this.state.mostrarDatosEquipo
        })
    }
/*
    TraerEscudoLocal = async () => {

        let Auxnombre = this.props.data.local.equiponombre
        let nombre = Auxnombre.replace(/ /g, "").toLowerCase()
        let idCampeonato = this.props.idCampeonato
        let nombreFoto = 'escudo' + idCampeonato + nombre
        let referencia = await ref(storage, `escudos/${nombreFoto}`)
        ////console.log(referencia)
        await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                this.setState({
                    escudoEquipoLocal: doc
                })

            }
            ////console.log(uri)
        }
        ).catch(e => {
            ////console.log(e)
        })
    }
    TraerEscudoVisitante = async () => {

        let Auxnombre = this.props.data.visitante.equiponombre
        let nombre = Auxnombre.replace(/ /g, "").toLowerCase()
        let idCampeonato = this.props.idCampeonato
        let nombreFoto = 'escudo' + idCampeonato + nombre
        let referencia = await ref(storage, `escudos/${nombreFoto}`)
        ////console.log(referencia)
        await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                this.setState({
                    escudoEquipoVisitante: doc
                })

            }
            ////console.log(uri)
        }
        ).catch(e => {
            ////console.log(e)
        })
    }

    MostrarFotoLocal = () => {
        const { escudoEquipoLocal } = this.state
        ////console.log(escudoEquipoLocal)
        if (escudoEquipoLocal.length !== 0) {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={escudoEquipoLocal} />
            )
        }
        else {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={require('../../respaldos/icono-escudoequipo.png')} />
            )
        }
    }

    MostrarFotoVisitante = () => {
        const { escudoEquipoVisitante } = this.state
        ////console.log(escudoEquipoLocal)
        if (escudoEquipoVisitante.length !== 0) {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={escudoEquipoVisitante} />
            )
        }
        else {
            return (
                <img className='fotoEscudo1' alt='fotoEscudo' src={require('../../respaldos/icono-escudoequipo.png')} />
            )
        }
    }
*/
    render() {
        //console.log(this.state.jugadores.length)
        let claseListaEquipos = 'listaEquipos'
        if (this.state.mostrarEquipo === true) {
            claseListaEquipos = 'listaEquiposPeque'
        }
        return (
            <div className='tablaPosiciones2'>
                {this.state.ListaEquipos === false ? (
                    <div className='listaEquiposPeque'>
                        {this.props.ListaequiposCategoria && this.props.ListaequiposCategoria.map((doc, i) => {
                            ////console.log(doc.escudo)
                            return (
                                <button onClick={() => this.mostrarEquipo(doc)} className='EquiposPeques'>
                                    {doc.escudo ? (
                                        <img src={doc.escudo} className='fotoEscudoPeque' />
                                    ) : (
                                        <img src={fotoEscudo} className='fotoEscudoPeque' />
                                    )}
                                    <a className='nombreEquipoPeque'>{doc.nombre} </a>
                                </button>
                            )
                        })}
                    </div>
                ) : null}
                {this.props.mostrarEtapa === true && (
                    <div className='tablaPosiciones'>
                        <div id='tablagruposGeneral' style={{
                            backgroundImage: `url(${this.state.fondoGrupal})`,
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}
                            className='tablaPosiciones1'>
                            <div className='tituloTabla1'>
                                <p style={this.state.colorTituloNombre} className='tituloTabla'>{this.props.Campeonato.nombre}</p>
                                <p style={this.state.colorTitulo} className='tituloTabla'>{this.props.eleccionMenu}  -  {this.props.categoriaElegida}</p>
                            </div>

                            <div className='bodyEquipos'>
                                {this.state.ListaEquipos === true ? (
                                    <div className='listaEquipos'>
                                        {this.props.ListaequiposCategoria && this.props.ListaequiposCategoria.map((doc, i) => {
                                            ////console.log(doc.escudo)
                                            return (
                                                <button onClick={() => this.mostrarEquipo(doc)} className='Equipos'>
                                                    {doc.escudo ? (
                                                        <img src={doc.escudo} className='fotoEscudo' />
                                                    ) : (
                                                        <img src={fotoEscudo} className='fotoEscudo' />
                                                    )}
                                                    <a className='nombreEquipo'>{doc.nombre} </a>
                                                </button>
                                            )
                                        })}
                                    </div>
                                ) : null}
                                {this.state.mostrarEquipo === true ? (
                                    <div className='muestraEquipo'>
                                        {this.state.equipoElegido ? (
                                            <div className='divLista'>
                                                {this.state.equipoElegido.escudo ? (

                                                    <img src={this.state.equipoElegido.escudo} className='EscudoGrande' />
                                                ) : (
                                                    <img src={fotoEscudo} className='EscudoGrande' />
                                                )}
                                                <br />
                                                <a className='Titulo' >{this.state.equipoElegido.nombre}</a>
                                                <br />
                                                <a className='Subtitulo'>{this.state.equipoElegido.idcategoria}</a>
                                                <br />
                                                {this.state.mostrarDatosEquipo === true ? (
                                                    <div>
                                                        <button className='botonJugadores' onClick={this.mostrarDatosEquipo}>MOSTRAR JUGADORES</button>
                                                    </div>
                                                ) : (
                                                    <div >
                                                        <button className='botonJugadores' onClick={this.mostrarDatosEquipo}>MOSTRAR DATOS ESTADÍSTICOS</button>
                                                        <div className='ListaJugadores'>
                                                        <div className='linea'></div>
                                                            <div className='ItemListaJugadores'>
                                                                <h3 className='ListaNumero'>NUMERO</h3>
                                                                <h3 className='ListaNombre'>NOMBRE</h3>
                                                                <h3 className='ListaId'>IDENTIFICACION</h3>
                                                            </div>
                                                            <div className='linea'></div>
                                                            <FlatList list={this.state.jugadores}

                                                                renderItem={(item, index) => <JugadoresLayout 
                                                                    key={item.key} 
                                                                    data={item}
                                                                    Campeonato={this.props.Campeonato}
                                                                    i={index}/>}
                                                                renderWhenEmpty={() => <div>Lista Vacia!</div>} />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}

                                    </div>
                                ) : (null)}
                            </div>
                            <div className='LogoHeader'>
                                <img className='bannerPie' src={this.state.bannerGrupal} />
                            </div>
                        </div>
                        {this.props.usuario !== 'usuario' ?
                            <div className='DivBotones'>
                                <div className='BotonesDescarga'>
                                    <a className='ImagenDescarga' href={this.state.PNGgrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                        <img src={this.state.PNGgrupo} width="250" />
                                    </a>
                                    <button onClick={() => this.descargarPngGrupos()} className='botonesExtras'>DESCARGAR PNG</button>
                                </div>
                                <div className='BotonesDescarga'>
                                    <a className='ImagenDescarga' href={this.state.JPGGrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                        <img src={this.state.JPGGrupo} width="250" />
                                    </a>
                                    <button onClick={() => this.descargarJPGGrupos()} className='botonesExtras'>DESCARGAR JPEG</button>
                                </div>
                            </div>
                            : null}
                    </div>
                )}
                <div className='FicherosSubir'>
                    <div className='Fichero'>
                        <img src={this.state.fondoTP} style={{ margin: 10 }} width="70" />
                        <input onChange={(e) => this.elegirFoto(e)} id='FondoTP' type='file' />
                        <button onClick={this.SubirFondo} className='botonesExtras'>SUBIR FONDO T.P.</button>
                    </div>
                    <div className='Fichero'>
                        <img src={this.state.bannerbajoTP} style={{ margin: 10 }} width="70" />
                        <input onChange={(e) => this.elegirFoto(e)} id='BannerBajoTP' type='file' />
                        <button onClick={this.SubirBanerTP} className='botonesExtras'>SUBIR BANNER BAJO T.P.</button>
                    </div>
                </div>
                
            </div>
        )
    };
}
