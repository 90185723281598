import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import '../../estilos/homeAdmin.css'
import fondo from '../../respaldos/fondo-posiciones-p.png'
import banner from '../../respaldos/pie-de-imagen-TP.png'
import domtoimage from 'dom-to-image';
import { storage } from '../../funciones/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Document, Page, View, Text, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../respaldos/logo-TACTICAPP.png'

export default class TablaGoleadores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fondoGrupal: '',
            fondoAcumulada: '',
            fondoClasificatoria: '',
            bannerGrupal: '',
            bannerAcumulada: '',
            bannerClasificatoria: '',
            colorTituloNombre: { color: '#063652' },
            colorTitulo: { color: 'black' },
            pieImagen: '../../respaldos/pie-de-imagen-TP.png',
            colorLetraTabla: { color: 'black' },
            PNGgrupo: '',
            PNGAcumulada: '',
            PNGclasificatoria: '',
            JPGGrupo: '',
            JPGAcumulada: '',
            JPGclasificatoria: '',
            fondoTP: null,
            bannerbajoTP: null
        }
    }

    componentDidMount() {
        this.traerFondo()
        this.traerBannerBajo()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.idCampeonato !== this.props.idCampeonato) {
            // ... do something
            this.traerFondo()
            this.traerBannerBajo()
        }
    }

    fondo = (i) => {
        let aux = this.props.catGrupos.length
        if (i < aux) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 2) - 1 && i < (aux * 3)) {
            return { backgroundColor: '#E4ECE7' }
        }
        if (i > (aux * 4) - 1 && i < (aux * 5)) {
            return { backgroundColor: '#E4ECE7' }
        }

    }

    fondo1 = (i) => {
        let aux = this.props.catGrupos.length
        if (i % 2) {
            return { backgroundColor: '#E4ECE7' }
        }
    }

    traerFondo = async () => {
        ////console.log(fondo)
        this.setState({
            fondoGrupal: fondo,
            fondoAcumulada: fondo,
            fondoClasificatoria: fondo
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then(doc => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    fondoGrupal: doc,
                    fondoAcumulada: doc,
                    fondoClasificatoria: doc
                })
            }

        }
        ).catch(e => {
            //console.log(e)
        })
    }

    traerBannerBajo = async () => {
        ////console.log(fondo)
        this.setState({
            bannerGrupal: banner,
            bannerAcumulada: banner,
            bannerClasificatoria: banner
        })
        let idCampeonato = this.props.idCampeonato

        let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
        ////console.log(referencia)
        let fondoFire = await getDownloadURL(referencia).then((doc, i) => {
            if (doc) {
                ////console.log(doc)
                this.setState({
                    bannerGrupal: doc,
                    bannerAcumulada: doc,
                    bannerClasificatoria: doc,
                })
            }
        }
        ).catch(e => {
            //console.log(e)
        })
    }

    descargarPngGrupos = () => {
        let node = document.getElementById('tablagruposGeneral')
        domtoimage.toPng(node)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.setState({
                    PNGgrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    descargarJPGGrupos = () => {
        let auxiii = document.getElementById('fixtureGeneral')
        ////console.log(auxiii)
        domtoimage.toJpeg(auxiii)
            .then((dataUrl) => {
                //console.log(dataUrl)
                //let aux = new Image();
                //aux.src = dataUrl;
                this.setState({
                    JPGGrupo: dataUrl
                })
                ////console.log(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    SubirFondo = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('FondoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpfondo/tpfondo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    fondoTP: null
                })
            )
            this.traerFondo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    SubirBanerTP = async () => {
        //BUSCAR LA MANERA DE SUBIR O TRAERLA CON LAS MEDIDAS NECESARIAS 
        let foto = document.getElementById('BannerBajoTP').files[0]
        ////console.log(foto)
        let idCampeonato = this.props.idCampeonato

        if (foto) {
            let referencia = ref(storage, `tpbannerbajo/tpbannerbajo${idCampeonato}`)
            await uploadBytes(referencia, foto).then(
                alert('Foto Subida'),
                this.setState({
                    bannerbajoTP: null
                })
            )
            this.traerBannerBajo()
        } else {
            alert('ELIGE UNA FOTO')
        }
    }

    elegirFoto = async (e) => {
        ////console.log(e.target.id)
        let idAux = e.target.id
        let foto = e.target.files[0]

        ////console.log(foto)
        let fotoOk = new FileReader()
        let url = fotoOk.readAsDataURL(foto)
        ////console.log(url)
        fotoOk.onloadend = function (e) {
            if (idAux === 'FondoTP') {
                this.setState({
                    fondoTP: [fotoOk.result]
                })
            }
            if (idAux === 'BannerBajoTP') {
                this.setState({
                    bannerbajoTP: [fotoOk.result]
                })
            }
        }.bind(this);

    }

    render() {
        ////console.log(this.state.bannerGrupal)
        return (
            <div className='tablaPosiciones'>

                <div className='tablaPosiciones'>
                    <div id='fixtureGeneral' style={{
                        backgroundImage: `url(${this.state.fondoGrupal})`,
                        backgroundPosition: 'top',
                        backgroundRepeat: 'no-repeat',
                    }} className='fixtureGeneral'>

                        <div className='LogoHeader'>
                            <img className='bannerPie' src={this.state.bannerGrupal} />
                        </div>
                    </div>
                    <div className='DivBotones'>
                        <div className='BotonesDescarga'>
                            <a className='ImagenDescarga' href={this.state.PNGgrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                <img src={this.state.PNGgrupo} width="250" />
                            </a>
                            <button onClick={() => this.descargarPngGrupos()} className='botonesExtras'>DESCARGAR PNG</button>
                        </div>
                        <div className='BotonesDescarga'>
                            <a className='ImagenDescarga' href={this.state.JPGGrupo} download={`TP Cat ${this.props.idcategoria} Grupo ${this.props.idgrupos}`}>
                                <img src={this.state.JPGGrupo} width="250" />
                            </a>
                            <button onClick={() => this.descargarJPGGrupos()} className='botonesExtras'>DESCARGAR JPEG</button>
                        </div>

                    </div>
                </div>



            </div>
        )
    };
}
